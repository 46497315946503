import { AxiosResponse } from 'axios';
import ExcuteApi from './api-integrations/excute-api';

class BookingApi {
  bookingPost = async (params: IBookingParams) => {
    const response: any = await ExcuteApi(`/api/v1/booking/line-booking`, params, 'post', 6e5, true, true);
    return response;
  };

  getJobCallHistory = async (params: IBookingProps) => {
    const response: any = await ExcuteApi(`api/v1/booking/`, params, 'get', 6e5, true, true);
    return response;
  };

  getDetail = async (id: string) => {
    const response: any = await ExcuteApi(`/api/v1/booking/${id}`, {}, 'get', 6e5, true, true);
    return response;
  }
}

export default new BookingApi();

export interface IBookingParams {
  jobId: string
  truckId?: string
  accepterUserId: string
  requesterType: 'JOB_OWNER' | 'TRUCK_OWNER'
  requesterUserId: string
}

export interface IBookingProps {
  descending?: boolean
  page: number
}
