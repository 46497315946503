import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DynamicTable from '@atlaskit/dynamic-table';
import { head, createRow, sortabled } from './dynamic-table/sample-data';
import { useMst } from '../../stores/root-store';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { defaultAlertSetting } from '../simple-data';
import Alert from '../alert';
import moment from 'moment';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import SearchForm from '../search-form';
import { UserNonPersistStore } from '../../stores/user-non-persist-store'
import { RowType } from '@atlaskit/dynamic-table/dist/types/types';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import { navigate } from 'gatsby';
import Swal from 'sweetalert2';

interface Props { }

const MAIN_COLOR: string = '#c4c4c4';
const DriverContainer: React.FC<Props> = observer(() => {
  const { t } = useTranslation();
  const { loginStore } = useMst();
  const [rowData, setRowData] = useState<(RowType)[]>([]);
  const [rowLength, setRowLength] = useState(10);
  const [alertSetting, setAlertSetting] = useState(defaultAlertSetting);
  const [page, setPage] = useState(1);
  const [isDesc, setIsDesc] = useState(true);
  const [searchValue, setSearchValue] = useState({});
  const [sortable, setSortable] = useState(sortabled);
  const [jobId, setJobId] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteFinished, setDeleteFinished] = useState<boolean>(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const breadcrumbs = (
    <Breadcrumbs onExpand={() => { }}>
      <BreadcrumbsItem text={t('driverManagement')} key="driver-management" />
    </Breadcrumbs>
  );

  const setInitialSortingAndPage = () => {
    const options = { page: 1, descending: true, roleId: 3, status: 'ACTIVE' };
    setSearchValue(options);
    UserNonPersistStore.getUsersV2(options);
  };

  useEffect(() => {
    setInitialSortingAndPage();

    return () => {
      UserNonPersistStore.clear();
    }
  }, []);

  useEffect(() => {
    if (UserNonPersistStore.error_response) {
      setAlertSetting({
        icon: 'error',
        show: true,
        type: 'general',
        title: UserNonPersistStore.error_response.title || '',
        content: UserNonPersistStore.error_response.content || '',
      });
    } else {
      setAlertSetting(defaultAlertSetting);
    }
  }, [UserNonPersistStore.error_response]);

  useEffect(() => {
    const userData = JSON.parse(JSON.stringify(UserNonPersistStore.userData));
    if (userData?.content?.length) {
      const rows = createRow(userData.content, loginStore.language, t, onOpenModal, onDelete);
      setRowData(rows);
      const rowLen = UserNonPersistStore?.pagination?.size;
      rowLen && setRowLength(rowLen);
    }
  }, [JSON.stringify(UserNonPersistStore.userData)]);

  useEffect(() => {
    if (jobId) {
      openModal();
    }
  }, [jobId])

  const onSearch = (value: string) => {
    console.log('value :>> ', value);
    if (value) {
      let search = {
        page: 1,
        textSearch: value,
        descending: true,
        roleId: 3
      };
      setPage(1);
      setSearchValue(search);
      UserNonPersistStore.getUsersV2(search);
    } else {
      setPage(1);
      setInitialSortingAndPage();
    }
  };

  const onHandlePage = (pagination: number) => {
    setPage(pagination);
    const searchParams = { ...searchValue, page: pagination };
    setSearchValue(searchParams);
    UserNonPersistStore.getUsersV2(searchParams);
  }

  const onOpenModal = (jobId: string) => {
    setJobId(jobId);
  };

  const onDelete = (userId: string) => {
    const red = '#E03616';
    const blue = '#3085D6';
    Swal.mixin({
      iconColor: red,
      confirmButtonColor: red,
      cancelButtonColor: blue,
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
    })
      .fire({
        title: t('deleteConfirmAlertTitle'),
        titleText: t('deleteConfirmAlertText'),
        icon: 'warning',
        showCancelButton: true,
      })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          UserNonPersistStore.deleteUser(userId)
          setDeleteFinished(true)
        }
      })
  }

  useEffect(() => {
    if (UserNonPersistStore.loadingDeleteUser && deleteFinished) {
      setInitialSortingAndPage();
      setDeleteFinished(false);
    }
  }, [UserNonPersistStore.loadingDeleteUser, deleteFinished])


  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <PageHeader breadcrumbs={breadcrumbs}>{t('driverManagement')}</PageHeader>
        <div style={{ marginTop: 38 }}>
          <Button
            iconBefore={<AddCircleIcon label="" />}
            onClick={() => navigate('/drivers/add')}
            appearance="warning"
            testId="addButton"
          >
            {t('addNewDriver')}
          </Button>
        </div>
      </div>

      {alertSetting.show && <Alert setting={alertSetting} />}

      <SearchForm onSearch={(value: any) => onSearch(value)} />

      <span>{`${t('resultsFound')}: ${UserNonPersistStore.pagination?.totalElements || 0}`}</span>

      <div className="history-call-dynamic-tbl">
        <DynamicTable
          head={head}
          rows={rowData}
          page={page}
          emptyView={<p>{t('noData')}</p>}
          testId="job-history-call"
          // sortKey={sortable.sortKey}
          // sortOrder={sortable.sortOrder === 'DESC' ? 'DESC' : 'ASC'}
          rowsPerPage={rowLength}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={UserNonPersistStore.loading}
          defaultSortOrder="DESC"
          onSort={(sort) => {
            const descending = !sortable[sort.key];
            const search = { ...searchValue, descending, sortBy: sort.key };
            setSortable({ ...sortable, [sort.key]: descending });
            setSearchValue(search);
            // userStore.getUsers(search);
          }}
          onSetPage={onHandlePage}
        />
      </div>
    </div>
  );
});
export default DriverContainer;
