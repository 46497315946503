import React from 'react';
// import About from '../../images/Aboutme.png';

export default function () {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#ffffff', padding: 50 }} color="#edf1f7">
      {/* <img src={About} /> */}
    </div>
  );
}
