import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMst } from '../../stores/root-store';
import Swal from 'sweetalert2';
import { IJob } from '../../services/job-api';
import { navigate } from 'gatsby';
import DurationFilter from '../../components/jobs/list/duration.filter';
import TruckTypesSelector from '../../components/dropdowns/truckType.selector';
import { IProductType } from '../../services/product-type-api';
import { useLiff } from 'react-liff';
import { CardBody } from '@paljs/ui/Card';
import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col'
import FlatList from 'flatlist-react';
import { Icon } from 'react-icons-kit'
import { location2 } from 'react-icons-kit/icomoon/location2'
import {
  COLOR_PRIMARY, COLOR_SUCCESS, COLOR_WHITE, CSS_WRAPER_TEXT,
  DELIVER_POINT, DIV_COUNT_TRUCK, DIV_TRUCK_TYPE, FLEX_ROW, FONT_SIZE_14, HEAD_TEXT, IMG_BACKGROUND, LIST_CONTAINER,
  MARGIN0,
  MARGIN_LEFT_DEL10, MIN_HEIGHT_CONTAINER, OVER_FLOW_HIDDEN, PADDING0, PICKUP_POINT, PRICE_LABEL, TRUCK_COUNT_CONTAINER
} from '../../components/jobs/liff-search.style';
import { MapTruckImageName } from '../../map-truck-image-name';
import { images } from '../../theme/images';

interface LineProfile {
  userId?: string | undefined
  displayName?: string | undefined
  // phoneNumber?: string | undefined
  pictureUrl?: string | undefined
  statusMessage?: string | undefined
  email?: string | undefined
}

interface INITIAL_STATE {
  hasMoreItems: boolean
  offset: number
  myApiData: Array<IJob> | []
  loading: boolean
}

const initialState: INITIAL_STATE = {
  hasMoreItems: false,
  offset: 1,
  myApiData: [],
  loading: true // important so the right blank message is shown from the start
}

const LiffSearch: React.FC = observer(() => {
  const { t } = useTranslation();
  const { error, liff, isLoggedIn, ready } = useLiff();
  const [lineProfile, setlineProfile] = useState<LineProfile>({})
  const [productTypes, setProductTypes] = useState<IProductType[]>([]);
  const { jobStore, versatileStore } = useMst();

  useEffect(() => {
    if (!isLoggedIn) return;
    (async () => {
      const profile = await liff.getProfile();
      setlineProfile(profile);
    })();
  }, [liff, isLoggedIn]);

  const [filterPlus, setfilterPlus] = useState<string | null>(null)
  const [truckTypeList, settruckType] = useState<string | null>(null)

  const onFilterDuration = (durationTime: string | null) => {
    if (!durationTime) return;
    console.log("Duration time :: ", durationTime)
    setfilterPlus(durationTime)
    resetOptionsPaging()
    // fetchData({
    //   page: 1,
    //   ...(durationTime && durationTime != "all_time" ? { duration: durationTime } : undefined),
    //   ...(truckTypeList ? { truckType: truckTypeList } : undefined)
    // })
    // fire search
  }
  const onTruckTypeFilterChange = (truckType: string | null) => {
    if (!truckType) return;
    console.log("Truck type select :: ", truckType)
    settruckType(truckType == "none" ? "none" : JSON.stringify([truckType]))
    resetOptionsPaging()
    // fetchData({
    //   page: 1,
    //   ...(filterPlus ? { duration: filterPlus } : undefined),
    //   ...(truckType && truckType != "none" ? { truckType: JSON.stringify([truckType]) } : undefined)
    // })
    // fire search
  }

  useEffect(() => {
    if (filterPlus || truckTypeList) {
      fetchData({
        page: 1,
        ...(filterPlus && filterPlus != "all_time" ? { duration: filterPlus } : undefined),
        ...(truckTypeList && truckTypeList != "none" ? { truckType: truckTypeList } : undefined)
      })
    }
  }, [filterPlus, truckTypeList])

  const resetOptionsPaging = () => {
    setState(prevState => ({
      ...prevState,
      offset: 1, myApiData: []
    }))
  }

  const fireError = (title: string | null, content: string | null) => {
    Swal.fire({
      icon: 'error',
      title: title || '',
      text: content || '',
    });
  };

  useEffect(() => {
    // jobStore.getJobsListNew(INITIAL_API_PARAMS);
    var node = document.querySelector('title');
    if (node) {
      node.textContent = t("findTransportation")
    }
    fetchData()
    // masterTypeStore.getProductTypes();
    if (!versatileStore.list || !versatileStore.listDropdown) versatileStore.find()
    if (!versatileStore.province) versatileStore.getProvince()
    if (!versatileStore.list_product_type) versatileStore.findProductType()
  }, []);

  useEffect(() => {
    if (jobStore.error_response) {
      const { title, content } = jobStore.error_response;
      fireError(title, content);
    }
    if (versatileStore.product_type_error) {
      const product_error = versatileStore.product_type_error;
      fireError("Product type failure", product_error);
    }
  }, [jobStore.error_response, versatileStore.product_type_error]);

  useEffect(() => {
    const { product_type_loading, list_product_type_pure } = versatileStore;
    if (!product_type_loading && list_product_type_pure?.length != null) setProductTypes(list_product_type_pure as IProductType[]);
  }, [versatileStore.list_product_type_pure]);

  const [{ hasMoreItems, offset, myApiData, loading }, setState] = useState(initialState)

  const fetchData = (filterJobs: any = null) => {
    console.log("Onfetch data duration filter :: ", filterPlus)
    console.log("Onfetch data truckType filter :: ", truckTypeList)
    jobStore.getJobsListNew({
      page: offset,
      status: 'NEW',
      includeExpireJob: true,
      descending: false,
      ...(filterPlus && filterPlus != "all_time" ? { duration: filterPlus } : undefined),
      ...(truckTypeList && truckTypeList != "none" ? { truckType: truckTypeList } : undefined),
      ...(filterJobs ? filterJobs : undefined),
    }).then(() => {
      const tmpListJobs: any = JSON.parse(JSON.stringify(jobStore.data_jobs_liff))
      console.log(`🚀  ->  tmpListJobs`, tmpListJobs);
      if (tmpListJobs && tmpListJobs.content?.length && tmpListJobs.content?.length > 0 && tmpListJobs.content != myApiData) {
        setState(prevState => ({
          offset: tmpListJobs.currentPage + 1,
          hasMoreItems: tmpListJobs.numberOfElements != 0 && tmpListJobs.numberOfElements % tmpListJobs.lengthPerPage == 0 ? true : false,
          myApiData: tmpListJobs.content,
          loading: jobStore.loading
        }))
      }
    })
  }

  const showBlank = () => {
    if (myApiData.length === 0 && jobStore.loading)
      return <div style={{ textAlign: 'center' }}>{t("job.loadingMore")}</div>

    return <div style={{ textAlign: 'center' }}>{t("job.noneList")}</div>
  }

  const renderItems = (item: any, index: number) => {
    const truckTypeName = versatileStore.truckTypeNameById(Number(item.truckType))
    const default_breakpoint = { xs: 12, lg: 12 }

    return <Row key={item.id} onClick={() => navigate(`/view/${item.id}`)} style={LIST_CONTAINER}>
      <Col breakPoint={default_breakpoint}>
        <img src={images[MapTruckImageName(item.truckType)]} style={IMG_BACKGROUND} />

        <div style={PICKUP_POINT}>
          <div style={COLOR_PRIMARY}>
            <Icon icon={location2} />
          </div>
          <span style={CSS_WRAPER_TEXT}>{t("job.pickupPoint") + " : " + (item.from?.name || "-")}</span>
        </div>
        <div style={DELIVER_POINT}>
          <div style={COLOR_SUCCESS}>
            <Icon icon={location2} />
          </div>
          <span style={CSS_WRAPER_TEXT}>{t("job.deliverPoint") + " : " + (item.to[0]?.name || "-")}</span>
        </div>

        <Col breakPoint={default_breakpoint} style={MARGIN_LEFT_DEL10}>
          <span style={FONT_SIZE_14}>{t("job.item") + " : " + item.productName}</span>
        </Col>
        <Col breakPoint={default_breakpoint} style={MARGIN_LEFT_DEL10}>
          <div style={FLEX_ROW}>
            <div style={TRUCK_COUNT_CONTAINER}>
              <span style={DIV_TRUCK_TYPE}>{(truckTypeName?.name || "-")}</span>
              {item?.requireTruckAmount && <span style={DIV_COUNT_TRUCK}>{+" " + (item.requireTruckAmount) + " " + t("job.unit")}</span>}
            </div>

          </div>
        </Col>
        <div style={PRICE_LABEL}>
          <span style={{ ...COLOR_WHITE, ...FONT_SIZE_14 }}>{
            `${item?.price ? item?.price.toString() : '-'} ${'\u0E3F'} / ${item?.priceType ? t(`job.${item?.priceType}`) : "-"}`
          }</span>
        </div>
      </Col>
    </Row>
  }

  console.log("Render Duration filter :: ", filterPlus)
  console.log("Render Truck type list :: ", truckTypeList)

  return (
    <Col style={{ ...PADDING0 }}>
      <CardBody style={{ ...OVER_FLOW_HIDDEN, ...MIN_HEIGHT_CONTAINER }}>
        <Col style={PADDING0}>
          <span style={HEAD_TEXT}>{t('job.findJob')}</span>
        </Col>
        <Row style={{ ...PADDING0, ...MARGIN0 }}>
          <Col breakPoint={{ xs: 5.5, lg: 5.5 }} style={{ ...PADDING0 }}>
            <DurationFilter t={t} minWidth='47vw' isSearchable={false}
              onChange={(option) => onFilterDuration(option?.value as string | null)} />
          </Col>
          <Col breakPoint={{ xs: 5.5, lg: 5.5 }} style={{ ...PADDING0, paddingLeft: 20 }}>
            <TruckTypesSelector
              maxWidth='47vw'
              placeholder={t('allTrucks')}
              includeNone={true}
              onSelect={onTruckTypeFilterChange}
              isSearchable={false}
            // fromMyTruckType={[]}
            />
          </Col>
        </Row>

        <Col style={PADDING0}>
          <span>{`${t('resultsFound')}: ${jobStore.data_jobs_liff?.totalElements || 0}`}</span>
        </Col>
        <Row style={{ ...PADDING0, ...MARGIN0 }}>
          <Col breakPoint={{ xs: 12, lg: 12 }} style={PADDING0}>
            <FlatList
              list={myApiData}
              renderItem={renderItems}
              renderWhenEmpty={showBlank} // let user know if initial data is loading or there is no data to show
              hasMoreItems={hasMoreItems}
              loadMoreItems={fetchData}
              paginationLoadingIndicator={<div>{t("job.loadingMore")}</div>}
              paginationLoadingIndicatorPosition="center"
            />

          </Col>
        </Row>
      </CardBody>
    </Col>
  );
});

export default LiffSearch;
