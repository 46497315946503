import React from 'react';
import { OptionsType, OptionType, ValueType } from '@atlaskit/select';
import { TFunction } from 'i18next';
import Dropdown from '../../dropdowns/generic';
import moment from 'moment'

interface DurationFilterProps {
  t: TFunction;
  onChange: (option: ValueType<OptionType, false>) => any;
  minWidth?: string;
  isSearchable?: boolean
}
const DurationFilter = ({ t, onChange, minWidth, isSearchable = true }: DurationFilterProps) => {
  const nowTime = new Date()
  const formatDate = "YYYY-MM-DD HH:mm:ss"

  // Today
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  const end = new Date();
  end.setHours(23, 59, 59, 999);
  const today = `${moment(start).format(formatDate)}to${moment(end).format(formatDate)}`;

  // Tomorrow
  const startTomorrow = new Date();
  startTomorrow.setDate(nowTime.getDate() + 1);
  startTomorrow.setHours(0, 0, 0, 0);
  const endTomorrow = new Date();
  endTomorrow.setDate(nowTime.getDate() + 1);
  endTomorrow.setHours(23, 59, 59, 999);
  const tomorrowFormat = `${moment(startTomorrow).format(formatDate)}to${moment(endTomorrow).format(formatDate)}`;

  // This week part
  const currentForWeek = new Date()
  var first = currentForWeek.getDate() - currentForWeek.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6
  var firstday = new Date(currentForWeek.setDate(first + 1));
  var lastday = new Date(currentForWeek.setDate(last + 1));
  const firstDateWeek = moment(firstday).format(formatDate)
  const lastDateWeek = moment(lastday).format(formatDate)

  // Next Week
  const currentForWeek2 = new Date()
  var first2 = currentForWeek2.getDate() - currentForWeek2.getDay();
  var last2 = first2 + 6;
  var firstday2 = new Date(currentForWeek2.setDate(first2 + 8));
  var lastday2 = new Date(currentForWeek2.setDate(last2 + 8));
  const firstDateWeek2 = moment(firstday2).format(formatDate)
  const lastDateWeek2 = moment(lastday2).format(formatDate)

  // This month
  var currentMonth = new Date();
  var firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  var lastDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
  const firstPartOfMonth = moment(firstDayOfMonth).format(formatDate)
  const lastPartOfMonth = moment(lastDayOfMonth).format(formatDate)

  // Next Month
  var currentNextMonth = new Date();
  let firstPartNextMonth
  let lastPartNextMonth
  if (currentNextMonth.getMonth() == 11) {
    firstPartNextMonth = new Date(currentNextMonth.getFullYear() + 1, 0, 1);
    lastPartNextMonth = new Date(currentNextMonth.getFullYear() + 1, 1, 0);
  } else {
    firstPartNextMonth = new Date(currentNextMonth.getFullYear(), currentNextMonth.getMonth() + 1, 1);
    lastPartNextMonth = new Date(currentNextMonth.getFullYear(), currentNextMonth.getMonth() + 2, 0);
  }
  const parseFirstNextMonth = moment(firstPartNextMonth).format(formatDate)
  const parseLastNextMonth = moment(lastPartNextMonth).format(formatDate)

  const TIME_OPTIONS: OptionsType = [
    { value: 'all_time', label: t('job.allTime') },
    { value: today, label: t('job.today') },
    { value: tomorrowFormat, label: t('job.tomorrow') },
    { value: `${firstDateWeek}to${lastDateWeek}`, label: t('job.thisWeek') },
    { value: `${firstDateWeek2}to${lastDateWeek2}`, label: t('job.nextWeek') },
    { value: `${firstPartOfMonth}to${lastPartOfMonth}`, label: t('job.thisMonth') },
    { value: `${parseFirstNextMonth}to${parseLastNextMonth}`, label: t('job.nextMonth') }]
  return <Dropdown options={TIME_OPTIONS} placeholder={t('duration')}
    minWidth={minWidth || "150px"} onChange={onChange} isSearchable={isSearchable} />;
};

export default DurationFilter;
