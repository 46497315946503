// leave off @2x/@3x
const images = {
  loading: require('../../images/loading.gif'),
  questionMark: require('../../images/question-mark.png'),
  Truck1: require('../../images/Truck1.png'),
  Truck2: require('../../images/Truck2.png'),
  Truck3: require('../../images/Truck3.png'),
  Truck4: require('../../images/Truck4.png'),
  Truck5: require('../../images/Truck5.png'),
  Truck6: require('../../images/Truck6.png'),
  Truck7: require('../../images/Truck7.png'),
  Truck8: require('../../images/Truck8.png'),
  Truck9: require('../../images/Truck9.png'),
  Truck10: require('../../images/Truck10.png'),
  Truck11: require('../../images/Truck11.png'),
  Truck12: require('../../images/Truck12.png'),
  Truck13: require('../../images/Truck13.png'),
  Truck14: require('../../images/Truck14.png'),
  Truck15: require('../../images/Truck15.png'),
  Truck16: require('../../images/Truck16.png'),
  Truck17: require('../../images/Truck17.png'),
  Truck18: require('../../images/Truck18.png'),
  Truck19: require('../../images/Truck19.png'),
  Truck20: require('../../images/Truck20.png'),
  Truck21: require('../../images/Truck21.png'),
  Truck22: require('../../images/Truck22.png'),
  Truck23: require('../../images/Truck23.png'),
  Truck24: require('../../images/Truck24.png'),
  Truck25: require('../../images/Truck25.png'),
  Truck26: require('../../images/Truck26.png'),
  Truck27: require('../../images/Truck27.png'),
  Truck28: require('../../images/Truck28.png'),
  Truck29: require('../../images/Truck29.png'),
  Truck30: require('../../images/Truck30.png'),
  Truck31: require('../../images/Truck31.png'),
  Truck32: require('../../images/Truck32.png'),
  Truck33: require('../../images/Truck33.png'),
  Truck34: require('../../images/Truck34.png'),
  Truck35: require('../../images/Truck35.png'),
  Truck36: require('../../images/Truck36.png'),
  cargolinkLogo: require('../../images/logo-Cargolink-white.png'),
  logo: require('../../images/logo.png'),
  frontTruck: require('../../images/FTruck.png'),
  backTruck: require('../../images/BTruck.png'),
  leftTruck: require('../../images/S1Truck.png'),
  rightTruck: require('../../images/S2Truck.png'),
  pinDrop: require('../../images/pin-drop.png'),
  pinDrop2: require('../../images/pin-drop2.png'),
  userIcon: require('../../images/icons/delivery-man.png'),

  warehouse: require('../../images/icons/warehouse.png'),
  truck: require('../../images/icons/delivery.png'),
  box: require('../../images/icons/box.png'),
  compass: require('../../images/icons/compass.png'),

  homeSearch: require('../../images/icons/search.png'),
  calculator: require('../../images/icons/calculator.png'),

  lineOA: require('../../images/line-oa.svg'),
};

export default images;
