import React, { useState, useEffect, Fragment, CSSProperties } from 'react'
import { observer } from 'mobx-react-lite';
import InlineEdit from '@atlaskit/inline-edit';
import styled from 'styled-components'
import images from '../../Themes/images'
import { useTranslation } from 'react-i18next';
import { useMst } from '../../../stores/root-store';
import Select, { ValueType } from '@atlaskit/select';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import truckApi, { CreateTruckResponse, PostTruckParams } from '../../../services/truck-api';
import Textfield from '@atlaskit/textfield';
import RadioButton, { ObjectRoundButton } from './radio-button'
import Form, { Field, FormFooter, HelperMessage, ErrorMessage, ValidMessage } from '@atlaskit/form';
import DriverSelector from '../../jobs/add/driver.selector';
import { useForm, Controller } from 'react-hook-form';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { AxiosResponse } from 'axios';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Icon } from 'react-icons-kit';
import { ic_person } from 'react-icons-kit/md/ic_person';
import CheckIcon from '@atlaskit/icon/glyph/check';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import { formatPhoneNumber } from '../../../utils';

interface DriverData {
  id: string
  fullName: string
  phoneNumber: string
  email: string
  avatar?: {
    object?: string | null
  }
}

interface TruckWidgetProps {
  title?: boolean | string | null | undefined
  truckType: string | number | null | undefined
  tipper: boolean | null | undefined
  truckAmount?: string | number | null | undefined
  loadingWeight?: string | number | null | undefined
  registrationNumber?: Array<string> | null | undefined
  stallHeight?: string | null | undefined
  disabled?: boolean
  driver?: DriverData
}

interface OptionType {
  label: string;
  value: string;
}

const IMAGE_PROFILE: CSSProperties = {
  width: 120,
  height: 'auto',
};

const TruckWidget = observer((props: TruckWidgetProps) => {
  const {
    title, truckType, tipper,
    truckAmount, loadingWeight,
    stallHeight, registrationNumber,
    driver } = props
  type Fields = 'truckType' | 'stallHeight' | 'loadingWeight' | 'tipper' | 'registrationNumber' | 'image';
  const { t } = useTranslation();
  const { versatileStore, truckTypesStore, truckStore } = useMst();
  const [optionsTruckTypes, setOptionsTruckTypes] = useState<Array<any>>([])
  const truckReqHeight: Array<number> = [9, 15, 24, 23, 3]
  const [reqHeight, setreqHeight] = useState<boolean>(false)
  const [dumpy, setdumpy] = useState<Array<ObjectRoundButton>>([{ label: t("DUMP"), value: true, active: tipper == true ? true : false },
  { label: t("NO_DUMP"), value: false, active: tipper == true ? false : true }])
  const { register, handleSubmit, watch, errors, setValue, control, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      driverId: null
    },
  });
  const [driverProfile, setDriverProfile] = useState<DriverData>();
  const [currentDriverProfile, setCurrentDriverProfile] = useState<DriverData>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const _getStallHeightList = (truckType: string | number | null | undefined, outputType: 'boolean' | 'array' = 'boolean') => {
    let plzSelect = { label: t('pleaseselect'), value: 'Please select' }
    let low = { label: t("LOW"), value: "LOW" }
    let medium = { label: t("MEDIUM"), value: "MEDIUM" }
    let height = { label: t("HIGH"), value: "HIGH" }
    let res = [plzSelect]
    let default_height = [plzSelect, low, medium, height]

    if (!truckType) {
      if (outputType == 'boolean')
        setreqHeight(false)
      else
        return default_height
    }

    let slotTruck = JSON.parse(JSON.stringify(versatileStore.list)).find((e: any) => e.id == truckType)
    let requiredStallHeight = false
    if (slotTruck) {
      let tmpTruckType = slotTruck.name.replace(/\s+/g, '').toLowerCase();
      if (tmpTruckType.includes("trailer") || tmpTruckType.includes("เทรเลอร์") || tmpTruckType.includes("18ล้อ")
        || tmpTruckType.includes("หัวลาก") || tmpTruckType.includes("รถพ่วง")) {
        if (Number(truckType) == 23) requiredStallHeight = true
        let lowTrailer = " (1.50 - 1.80 m)"
        let mediumTrailer = " (1.80 - 2.00 m)"
        let heightTrailer = " (2.20 - 2.50 m)"
        low.label = low.label + lowTrailer
        medium.label = medium.label + mediumTrailer
        height.label = height.label + heightTrailer
        res.push(low, medium, height)
      }
      else if (tmpTruckType.includes("4wheels") || tmpTruckType.includes("4ล้อ")) {
        if (Number(truckType) == 3) requiredStallHeight = true
        let low4Wheels = " (~ 1.4 m)"
        let height4Wheels = " (~ 2.1 m)"
        low.label = low.label + low4Wheels
        height.label = height.label + height4Wheels
        res.push(low, height)
      } else if (tmpTruckType.includes("6wheels") || tmpTruckType.includes("6ล้อ")) {
        if (Number(truckType) == 9) requiredStallHeight = true
        let low6Wheels = " (~ 2.0 m)"
        let height6Wheels = " (~ 3.0 m)"
        low.label = low.label + low6Wheels
        height.label = height.label + height6Wheels
        res.push(low, height)
      } else if (tmpTruckType.includes("10wheels") || tmpTruckType.includes("10ล้อ")) {
        if (Number(truckType) == 15 || Number(truckType) == 24) requiredStallHeight = true
        let medium10Wheels = " (~ 2.5 m)"
        medium.label = medium.label + medium10Wheels
        res.push(medium)
      }
      else {
        res.push(low, medium, height)
      }

      if (outputType == 'boolean')
        setreqHeight(requiredStallHeight)
      else
        return res

    } else {

      if (outputType == 'boolean')
        setreqHeight(requiredStallHeight)
      else
        return default_height
    }
  }

  const getTruckTypeGroup = async () => {
    await versatileStore.find()
  }

  useEffect(() => {
    if (!versatileStore.list || !versatileStore.listDropdown) getTruckTypeGroup()
    // if (typeof truckType == 'string' || typeof truckType == 'number')
    //   _getStallHeightList(truckType, 'boolean')
  }, []);

  useEffect(() => {
    if (driver) {
      const driverData = JSON.parse(JSON.stringify(driver));
      setDriverProfile(driverData);
      setCurrentDriverProfile(driverData);
    }
  }, [driver])

  console.log("Tipper props :: ", tipper)
  console.log("Dumpy state :: ", dumpy)

  useEffect(() => {
    let tmpDumpy = dumpy
    tmpDumpy.forEach((e: ObjectRoundButton) => {
      if (e.value == tipper) e.active = true
      else e.active = false
    })
    console.log("TMP DUMPY :: ", tmpDumpy)
    setdumpy(tmpDumpy)
  }, [tipper])

  useEffect(() => {
    let tmpTruckOptions = JSON.parse(JSON.stringify(versatileStore.listDropdown))
    if (tmpTruckOptions) {
      setOptionsTruckTypes(tmpTruckOptions)
      if (typeof truckType == 'string' || typeof truckType == 'number')
        _getStallHeightList(truckType, 'boolean')
    }
  }, [JSON.stringify(versatileStore.listDropdown)])

  const handleSave = async (field: Fields, value: any) => {
    console.log("FIELD :: ", field)
    console.log("Value select :; ", value)
    const payload: Partial<PostTruckParams> = {
      [field]: field == "registrationNumber" ?
        value.split(',') : value
    };
    if (typeof title == 'string') {
      await truckStore.patchTruck(payload, title)
      truckStore.getTruckById({ truckId: title })
    }
  };

  const onSubmit = (formState: any) => {
    console.log('formState :>> ', formState);
    const payload: PostTruckParams = {
      driverId: formState.driverId,
    };
    const MODAL_TIMEOUT = 1000;

    Swal.fire({
      icon: 'info',
      text: t('confirmForCreateDriver'),
      showCancelButton: true,
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
            setIsOpen(false);
            truckApi
              .patchTruck(payload, title + '')
              .then((response) => {
                if (response && response.status < 300) {
                  Swal.hideLoading();
                  const data = (response as AxiosResponse<any>).data;
                  console.log('add truck response', data);
                  Swal.update({
                    icon: 'success',
                    titleText: '',
                    text: t('createDriverSuccess'),
                    showConfirmButton: false,
                  });
                  return setTimeout(() => {
                    Swal.close();
                  }, MODAL_TIMEOUT);
                } else {
                  Swal.fire({
                    icon: 'error',
                    text: t('createTruckErrorByUser'),
                  });
                  console.error('create truck: truck error', response);
                }
              })
              .catch((error) => {
                console.error('Error while create this truck', error);
                Swal.fire({
                  icon: 'error',
                  text: 'Error while create this truck',
                });
              });
          },
        })
      }
    })
  };

  const checkCurrentValue = ({ items, value }: any) => {
    const driverData = items?.find((item: any) => item.id === value);
    if (driverData) {
      setDriverProfile({
        id: value,
        fullName: driverData?.fullName,
        email: driverData?.email,
        phoneNumber: driverData?.phoneNumber,
        avatar: {
          object: driverData?.avatar
        }
      })
      setIsOpen(true);
    }
  }

  const onCancel = () => {
    setDriverProfile(currentDriverProfile);
    setIsOpen(false);
  }

  let truckName: any
  if (truckType)
    truckName = truckTypesStore.truckTypeNameById(truckType)

  const tmpListTruckType = JSON.parse(JSON.stringify(versatileStore.list))
  let stallHeightList: any
  if (tmpListTruckType) {
    stallHeightList = truckType ? null : _getStallHeightList(truckType, 'array')

    if (typeof truckType == 'number' || typeof truckType == 'string') {
      console.log("Can select stall height :: ", truckReqHeight.includes(+truckType))
      stallHeightList = _getStallHeightList(truckType, 'array')
    }
  }
  console.log("Stall height :: ", stallHeight)
  console.log("Stall height options :: ", stallHeightList)
  return (
    <>
      <Row>
        {/* <Icon icon={truck} size={30} /> */}
        {title &&
          <>
            <img src={images.truck} style={{ width: 34, height: 34 }} />
            <h2 style={{ margin: 0, marginLeft: 10 }}>{typeof title == 'string' ? title : 'รถที่ต้องการ'}</h2>
          </>
        }
      </Row>
      <Row>
        <Col flex={1}>
          <Label>ประเภทรถ</Label>
          <InlineEdit<ValueType<OptionType, true>>
            defaultValue={truckType}
            editView={(fieldProps) => (
              <EditViewContainer>
                <Select {...fieldProps} options={optionsTruckTypes} autoFocus openMenuOnFocus />
              </EditViewContainer>
            )}
            readView={() => (
              <ReadViewContainer data-testid="truckTypeField">
                {truckName?.name || ''}
              </ReadViewContainer>
            )}
            onConfirm={(value) => {
              if (value?.value)
                return handleSave('truckType', value.value);
            }}
          />
        </Col>
        <Col flex={1}>
          <Label>คอก</Label>
          {/* <Value>{stallHeight || '-'}</Value> */}
          <InlineEdit<ValueType<OptionType, true>>
            defaultValue={stallHeight}
            editView={(fieldProps) => (
              <EditViewContainer>
                <Select {...fieldProps} isDisabled={typeof truckType == 'string' || typeof truckType == 'number' ?
                  (!truckReqHeight.includes(+truckType) ? true : false) : false} options={stallHeightList || []} autoFocus openMenuOnFocus />
              </EditViewContainer>
            )}
            readView={() => (
              <ReadViewContainer data-testid="stallHeightField">
                {stallHeight || 'Please select'}
              </ReadViewContainer>
            )}
            onConfirm={(value) => {
              if (value?.value)
                return handleSave('stallHeight', value.value);
            }}
          />
        </Col>
      </Row>

      <Row>
        {props.hasOwnProperty('truckAmount') && <Col flex={1}>
          <Label>จำนวนรถที่ต้องการ</Label>
          <Value>{truckAmount || '-'}{' คัน'}</Value>
        </Col>}

        {props.hasOwnProperty('loadingWeight') && <Col flex={1}>
          <Label>น้ำหนักบรรทุก</Label>
          <InlineEdit
            defaultValue={loadingWeight}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} css={{ width: '100%' }} autoFocus />
            )}
            readView={() => (
              <ReadViewContainer data-testid="userFullname">
                <WeightT isNoData={!loadingWeight}>{loadingWeight || "-"}{" " + t("ton")}</WeightT>
              </ReadViewContainer>
            )}
            onConfirm={(value) => {
              if (!value) return;
              return handleSave('loadingWeight', value);
            }}
          />
        </Col>}

        <Col flex={1}>
          <Label>การลงสินค้า</Label>
          <RadioButton list={dumpy} onPress={(items: ObjectRoundButton) => handleSave('tipper', items.value)} />
        </Col>
      </Row>

      <Row>
        {registrationNumber && <Col flex={1}>
          <Label>เลขทะเบียน</Label>
          {/* <Value>{registrationNumber.join(', ')}</Value> */}
          <InlineEdit
            defaultValue={registrationNumber.join(', ')}
            editView={({ errorMessage, ...fieldProps }) => (
              <Textfield {...fieldProps} css={{ width: '100%' }} autoFocus />
            )}
            readView={() => (
              <ReadViewContainer data-testid="userFullname">
                <WeightT isNoData={!registrationNumber}>{registrationNumber.join(', ') || "-"}</WeightT>
              </ReadViewContainer>
            )}
            onConfirm={(value) => {
              console.log("Vlaue REGISTRATION NUMBER :: => ", value)
              if (!value) return;
              return handleSave('registrationNumber', value);
            }}
          />
        </Col>}
      </Row>

      <Row>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '80%' }}>
          <Field label={t('drivers')} name="driverId" isRequired={false}>
            {({ fieldProps }: any) => {
              return (
                <Fragment>
                  <Controller
                    control={control}
                    name="driverId"
                    render={({ onChange, value }) => {
                      return (
                        <DriverSelector
                          {...fieldProps}
                          maxWidth="100%"
                          value={value}
                          onSelect={onChange}
                          currentValue={checkCurrentValue}
                          placeholder={t('drivers')}
                          noResultsMessage={t('noData')}
                        />
                      );
                    }}
                    rules={{ required: false }}
                  />
                </Fragment>
              );
            }}
          </Field>

          {isOpen && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
            <ButtonGroup>
              <IconBtn type='button' onClick={onCancel}><EditorCloseIcon label='close-icon' size={'small'} /></IconBtn>
              <IconBtn type='submit'><CheckIcon label='check-icon' size={'small'} /></IconBtn>
            </ButtonGroup>
          </div>}
        </form>
      </Row>

      <Row>
        {driverProfile && (<div style={{ marginTop: 30, width: '80%' }}>
          <Row style={{
            border: '1px solid #ddd',
            borderRadius: 5,
            padding: '5px 0 15px'
          }}>
            <Col
              flex={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ImageFram>
                <ImagePreview>
                  {driverProfile?.avatar?.object ? (
                    <img src={driverProfile.avatar.object} alt="" style={IMAGE_PROFILE} />
                  ) : (
                    <Icon icon={ic_person} size={50} />
                  )}
                </ImagePreview>
              </ImageFram>
            </Col>

            <Col flex={3}>
              <FieldWrapperBox>
                <ReadViewContainer data-testid="userFullname" style={{ paddingLeft: 0 }}>
                  <Name isNoData={!driverProfile?.fullName}>{driverProfile?.fullName || t('fullNamePlaceholder')}</Name>
                </ReadViewContainer>
              </FieldWrapperBox>

              <FieldWrapperBox>
                <Label>{t('phoneNumber')} :</Label>
                <ReadViewContainer data-testid="phoneNumberField">
                  {formatPhoneNumber(driverProfile?.phoneNumber) || <div style={{ color: '#aaa' }}>{'-'}</div>}
                </ReadViewContainer>
              </FieldWrapperBox>

              <FieldWrapperBox>
                <Label>{t('email')} :</Label>
                <ReadViewContainer data-testid="emailField">
                  {driverProfile?.email || <div style={{ color: '#aaa' }}>{'-'}</div>}
                </ReadViewContainer>
              </FieldWrapperBox>
            </Col>
          </Row>
        </div>)}
      </Row>
    </>
  )
})

export default TruckWidget

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
`;

const Col = styled.div<{ flex: number }>`
  display: flex;
  flex: ${props => props.flex | 1};
  flex-direction: column;
`;

const Label = styled.span`
  color: #999;
  font-size: 13px;
`;

const Value = styled.span`
  font-size: 20px;
`;


const EditViewContainer = styled.div`
  z-index: 300;
  position: relative;
  width: 200px;
`;

const ReadViewContainer = styled.div`
  display: flex;
  // font-size: ${fontSize()}px;
  // line-height: ${(gridSize() * 2.5) / fontSize()};
  max-width: 100%;
  // min-height: ${(gridSize() * 2.5) / fontSize()}em;
  padding: 5px ${gridSize() - 2}px;
  word-break: break-word;
`;


interface TextWeightProps {
  isNoData?: boolean;
}
const WeightT = styled.p<TextWeightProps>`
  color: ${({ isNoData }) => (isNoData ? '#AAA' : 'inherit')};
  margin: 0 0 10px 0;
  font-size: 1.125rem;
  font-weight: 700;

  :first-letter {
    text-transform: capitalize;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailLabel = styled.div`
  margin-top: 8px;
  color: #aaa;
`;

const FieldWrapperBox = styled(FieldWrapper)`
  margin-top: 8px;
`

const HeaderBox = styled.div`
  padding-left: 15px;
  margin-top: 8px;
  display: flex;
  align-items: center;
`

const Topic = styled.span`
  padding-left: 10px;
  font-size: 18px;
  font-weight: bold;
`

const ItemBox = styled.div`
  margin: 0 3px;
  border-radius: 5px;
  max-height: 650px;
  overflow: auto;
  background-color: #ededed;
`

const ButtonLoadMore = styled(Button)`
  border: 1px solid #cccccc !important;
  color: #000 !important;
  background-color: #cccccc !important;
  align-items: center;
`;

const Warning = styled.span`
  color: red;
`

const ImageFram = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ImagePreview = styled.div`
  width: 80px;
  height: 80px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
`;

interface FullNameProps {
  isNoData?: boolean;
}
const Name = styled.p<FullNameProps>`
  color: ${({ isNoData }) => (isNoData ? '#AAA' : 'inherit')};
  margin: 0 0 10px 0;
  font-size: 1.125rem;
  font-weight: 700;

  :first-letter {
    text-transform: capitalize;
  }
`;

const IconBtn = styled(Button)`
  box-shadow: var(--ds-overlay,0 4px 8px -2px rgba(9,30,66,0.25),0 0 1px rgba(9,30,66,0.31));
  background-color: #FFFFFF;
  width: 38px;
  z-index: 200;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 14px;
`
