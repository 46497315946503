import React, { useState, useEffect, CSSProperties, useCallback, memo, Fragment, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Form, { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { useMst } from '../../stores/root-store';
import { observer } from 'mobx-react-lite';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { navigate } from 'gatsby';
import PageHeader from '@atlaskit/page-header';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Collapse from '../../components/collapse/collapse';
import images from '../../components/Themes/images';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import { DatePicker } from '@atlaskit/datetime-picker';
import Button from '@atlaskit/button';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import LottieView from 'react-lottie';
import Spinner from '@atlaskit/spinner';
import SearchIcon from '@atlaskit/icon/glyph/search';
import MoreIcon from '@atlaskit/icon/glyph/more';
import Select from 'react-select';
import { TransportationStore } from '../../stores/transportation-store';
import moment from 'moment';
import { TripStore } from '../../stores/trip-store';
import Swal, { SweetAlertResult } from 'sweetalert2';
import TextInputSelected from '../../components/text-input-selected/text-input-selected';
import DriverSelector from '../../components/jobs/add/driver.selector';
import { formatPhoneNumber } from '../../utils';
import LoadingBar from 'react-top-loading-bar';

interface LocationProps {
  header: string;
  content: string;
  img: 'pinDrop' | 'pinDrop2';
}

interface HeaderProps {
  text: string
  img: 'box' | 'truck'
}

const LEFT_RIGHT_SPACING: CSSProperties = {
  paddingLeft: 10,
  paddingRight: 10,
};

const VEHICLE_DETAIL_BOX: CSSProperties = {
  backgroundColor: '#fbfbfb',
  width: '100%',
  paddingLeft: 15,
  paddingRight: 15,
};

const VEHICLE_BACKGROUND_IMGAE: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: '-25%',
  opacity: 0.4,
  display: 'flex',
  alignItems: 'baseline',
};

const TRASH: CSSProperties = {
  color: '#ff0000',
  position: 'absolute',
  right: 0,
  paddingRight: 15,
  cursor: 'pointer',
};

const DROP_BOX_SHOW: CSSProperties = {
  position: 'relative',
  marginTop: 15,
  minHeight: 200,
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 10,
  border: '1px dashed #cfcfcf',
  borderRadius: 5,
  transitionProperty: 'all',
  transitionDuration: '0.5s',
  transitionTimingFunction: 'ease',
};

const DROP_BOX_HIDE: CSSProperties = {
  minHeight: 0,
  transitionProperty: 'all',
  transitionDuration: '0.5s',
  transitionTimingFunction: 'ease',
  maxHeight: 735,
  overflowX: 'scroll',
};

const DROP_BOX_CONTENT: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const SEARCH_ICON: CSSProperties = {
  position: 'absolute',
  left: 9,
  top: 7,
  color: '#cfcfcf',
  zIndex: 99,
};

const LOADING_ICON: CSSProperties = {
  position: 'absolute',
  right: 50,
  top: 5,
  color: '#cfcfcf',
  zIndex: 99,
};

const TRIANGLE_TOPLEFT: CSSProperties = {
  width: 120,
  height: 0,
  borderTop: '57px solid #ffc107',
  borderRight: '40px solid transparent',
  position: 'absolute',
  clear: 'both',
  top: 0,
  left: 0,
};

const NEW_ICON: CSSProperties = {
  position: 'absolute',
  top: -14,
  left: -1,
};

const TruckAnimate = () => (
  <LottieView
    options={{
      autoplay: true,
      loop: true,
      animationData: require('../../images/animations/trruck-loading.json'),
    }}
  />
);

const Dots = () => (
  <LottieView
    options={{
      autoplay: true,
      loop: true,
      animationData: require('../../images/animations/dots-loading.json'),
    }}
    width={60}
    height={40}
  />
);

const Image = memo(
  ({ src, id, onError }: any) => (
    <img
      src={src}
      style={{
        width: 25,
        height: 25,
        borderRadius: '50%',
        backgroundColor: '#ebeef3',
        padding: 2,
        display: 'flex',
        alignItems: 'center',
      }}
      onError={onError}
    />
  ),
  (prevProps, nextProps) => {
    if (prevProps.id === nextProps.id) {
      return true;
    }
    return false;
  },
);

const Header = ({ text, img }: HeaderProps) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, paddingLeft: 8 }}>
    {/* <div style={TRIANGLE_TOPLEFT}></div> */}
    <img src={images[img]} style={{ width: 35, height: 35 }} />
    <h4 style={{ ...LEFT_RIGHT_SPACING, margin: 0, position: 'relative' }}>{text}</h4>
  </div>
);

const reorder = (list: [], startIndex: number, endIndex: number): object => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source: any, destination: any, droppableSource: any, droppableDestination: any): {} => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  let result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any): CSSProperties => ({
  userSelect: 'none',
  padding: '5px 15px',
  margin: `0 0 15px 0`,
  borderRadius: 5,
  position: 'relative',
  overflow: 'hidden',
  background: isDragging ? '#ffc107' : '#fff',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
  background: isDraggingOver ? 'lightblue' : '#ebeef3',
  padding: '15px 15px 5px 15px',
  margin: '3px',
  flex: 1,
  borderRadius: 5,
});

const Location = ({ content, header, img }: LocationProps) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ flex: '5%', display: 'flex' }}>
      <img src={images[img]} style={{ width: 16, borderRadius: '50%', backgroundColor: '#ebeef3', padding: 2 }} />
    </div>
    <Value style={{ flex: '8%' }}>{`${header} :`}</Value>
    <Value style={{ flex: '87%' }}>{content}</Value>
  </div>
);

const Detail = ({ header, content, style = {} }: any) => (
  <div style={style}>
    <Label>{`${header} :`}</Label>
    <Value>{content}</Value>
  </div>
);

const DetailSmall = ({ header, content, style = {} }: any) => (
  <div style={style}>
    <Label style={{ margin: '5px 0' }}>{`${header} :`}</Label>
    <ValueSmall>{content}</ValueSmall>
  </div>
);

const compareArray = (array1: any[], array2: any[]): boolean => {
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every((value, index) => value === array2Sorted[index])
  );
};

interface MasterTypeProps {
  id: string;
  name: string;
}

interface ITruckTypeSelectedOptionProps {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface Props { }

let truckPage: number = 1;

const TripsInfo: React.FC<Props> = observer((props: any) => {
  const { jobStore, truckStore, truckTypesStore, productTypesStore } = useMst();
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const [state, setState] = useState<any>({
    truckSelected: [],
    trucks: [],
  });
  const [truckTypes, setTruckTypes] = useState<MasterTypeProps | any>({});
  const [truckTypeSelectedOption, setTruckTypeSelectedOption] = useState<Array<ITruckTypeSelectedOptionProps>>([]);
  const [productTypes, setProductTypes] = useState<MasterTypeProps | any>({});
  const [searchTruck, setSearchTruck] = useState<any>('');
  const [isDragStart, setIsDragStart] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('none');
  const [dateSelected, setDateSelected] = useState<any>({});
  const [originalTruckIds, setOriginalTruckIds] = useState<any[]>([]);
  const [newTruckIds, setNewTruckIds] = useState<any[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [driverValue, setDriverValue] = useState<any>({});

  useEffect(() => {
    ref?.current?.continuousStart();

    return () => {
      // jobStore.clearJobs();
      // truckStore.clearTrucks();
      TransportationStore.clearJobDetail();
    };
  }, []);

  useEffect(() => {
    // jobStore.getJobById({ jobId: props.jobId });
    TransportationStore.getJobDetail(props.jobId, { isDeleted: false });
  }, [props.jobId]);

  useEffect(() => {
    if (TransportationStore.jobDetail) {
      const jobDetail = JSON.parse(JSON.stringify(TransportationStore.jobDetail));
      const trips = jobDetail.trips;
      const trucks: any = [];
      if (trips && trips?.length) {
        const drivers: any = {}
        const truckList = trips.map((trip: any) => {
          if (trip.driver?.id && trip.truck?.id) {
            drivers[trip.truck.id] = trip.driver;
          }
          return {
            ...trip.truck,
            tripId: trip.id,
            old: true,
            startDate: trip.startDate,
          }
        });
        trucks.push(...truckList);
        setDriverValue(drivers);
      } else if (jobDetail?.quotations) {
        const truckList = jobDetail.quotations.map((quot: any) => ({ ...quot.truck, tripId: quot.id, old: true }));
        trucks.push(...truckList);
      }

      console.log('trucks :>> ', trucks);

      if (trucks && trucks?.length) {
        const startDate: any = {};
        const truckIds: any[] = [];
        trucks.forEach((truck: any) => {
          startDate[truck.id] = truck.startDate;
          truckIds.push(truck.id);
        });
        setDateSelected(startDate);

        setOriginalTruckIds(truckIds);
        setNewTruckIds(truckIds);
      }

      setState((prev: any) => ({
        ...prev,
        truckSelected: trucks,
      }));
      ref?.current?.complete();
    }
  }, [JSON.stringify(TransportationStore.jobDetail)]);

  useEffect(() => {
    if (!truckTypesStore.data) {
      truckTypesStore.getTruckTypes();
    } else {
      const truckTypeData = JSON.parse(JSON.stringify(truckTypesStore.data));
      const truckTypeOptions = truckTypeData.map((truckType: any) => ({
        value: truckType.id.toString(),
        label: truckType.name,
      }));
      setTruckTypeSelectedOption(truckTypeOptions);

      const newTruckType = truckTypeData.reduce(
        (obj: any, item: any) => ({
          ...obj,
          [item['id']]: item.name,
        }),
        {},
      );
      setTruckTypes(newTruckType);
    }
  }, [truckTypesStore.data]);

  useEffect(() => {
    if (!productTypesStore.data) {
      productTypesStore.getProductTypes();
    } else {
      const newProductType = JSON.parse(JSON.stringify(productTypesStore.data)).reduce(
        (obj: any, item: any) => ({
          ...obj,
          [item['id']]: item.name,
        }),
        {},
      );
      setProductTypes(newProductType);
    }
  }, [productTypesStore.data]);

  useEffect(() => {
    if (truckStore.truckList?.content?.length) {
      setState((prev: any) => ({
        ...prev,
        trucks: JSON.parse(JSON.stringify(truckStore.truckList?.content)),
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        trucks: [],
      }));
    }
  }, [JSON.stringify(truckStore.truckList)]);

  useEffect(() => {
    if (state.truckSelected?.length) {
      // setIsDisabled(compareArray())
      const tripIds: any[] = [];
      state.truckSelected.forEach((truck: any) => tripIds.push(truck.id));
      setNewTruckIds(tripIds);
    } else {
      setNewTruckIds([]);
    }
  }, [JSON.stringify(state.truckSelected)]);

  useEffect(() => {
    if (newTruckIds.length) {
      setIsDisabled(compareArray(originalTruckIds, newTruckIds));
    }
  }, [JSON.stringify(newTruckIds)]);

  const droppableIds: any = {
    droppable1: 'trucks',
    droppable2: 'truckSelected',
  };

  const breadcrumbs = (
    <Breadcrumbs onExpand={() => { }}>
      <BreadcrumbsItem onClick={() => navigate('/trips')} text={t('trip.management')} key="trips-management" />
      <BreadcrumbsItem text={t('job.info')} key="job-info" />
    </Breadcrumbs>
  );

  const getList = (id: string): any => state[droppableIds[id]];

  const onDragEnd = (result: any) => {
    setIsDragStart(false);
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items: object = reorder(getList(source.droppableId), source.index, destination.index);

      let copiedState: any = Object.assign({}, state);

      if (source.droppableId === 'droppable2') {
        copiedState.truckSelected = items;
      } else if (source.droppableId === 'droppable1') {
        copiedState.trucks = items;
      }

      setState(copiedState);
    } else {
      const result: any = move(getList(source.droppableId), getList(destination.droppableId), source, destination);
      console.log('result :>> ', result);

      setState({
        trucks: result.droppable1 ? result.droppable1 : state.trucks,
        truckSelected: result.droppable2 ? result.droppable2 : state.truckSelected,
      });
    }
  };

  const onChangeValueTruck = (e: any) => {
    const value = e.target.value;
    setSearchTruck(value);
  };

  const onSubmitTruck = (value: string) => {
    setSelectedOption('');
    setSearchTruck(value);
    truckStore.clearTrucks();
    truckStore.getTrucksListWithoutEmptyContent({
      page: 1,
      descending: true,
      ...(value ? { searchText: value } : undefined)
    });
    truckPage = 1;
  };

  const loadMoreTruck = (truckTypeId?: number) => {
    let params: any = {};
    if (truckTypeId) {
      params.truckTypes = JSON.stringify([truckTypeId])
    } else if (searchTruck) {
      params.searchText = searchTruck
    }
    truckStore.getTrucksListWithoutEmptyContent({
      page: ++truckPage,
      descending: true,
      ...params
    });
  };

  const onSelectedTruckOptions = (value: string) => {
    setSelectedOption(value);
    setSearchTruck('');
    truckStore.clearTrucks();
    truckStore.getTrucksListWithoutEmptyContent({
      page: 1,
      descending: true,
      truckTypes: JSON.stringify([+value])
    });
    truckPage = 1;
  };

  const onImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = images.pinDrop;
  };

  const setNewTripOnDroppable = (truckId: string, truckDetail: any[]): void => {
    const newTruckList = state.trucks;
    newTruckList.push(truckDetail);

    const removeTruckList = state.truckSelected.filter((truck: any) => truck.id !== truckId);

    setState({
      trucks: newTruckList,
      truckSelected: removeTruckList,
    });
  };

  const removeTrip = (truckId: string, tripId: string) => {
    const truckDetail = state.truckSelected.find((truck: any) => truck.id === truckId);
    if (truckDetail.old) {
      Swal.fire({
        icon: 'warning',
        text: `ยืนยันการลบทริป!`,
        showCancelButton: true,
      }).then((result: SweetAlertResult) => {
        if (result.isConfirmed) {
          Swal.fire('Deleted!', '', 'success');
          const newDriverValue = JSON.parse(JSON.stringify(driverValue));
          delete newDriverValue[truckId];
          setDriverValue(newDriverValue);
          TripStore.delete(tripId);
          setNewTripOnDroppable(truckId, truckDetail);
        }
      });
    } else {
      setNewTripOnDroppable(truckId, truckDetail);
    }
  };

  const truckDroppable = {
    droppableId: 'droppable1',
    listId: 'trucks',
    title: 'Search trucks',
    onChange: onChangeValueTruck,
    onSubmit: onSubmitTruck,
  };

  const truckSelectedDroppable = {
    droppableId: 'droppable2',
    listId: 'truckSelected',
    title: '',
  };

  const onSubmit = (): void => {
    const truckSelected = state.truckSelected.map((truck: any) => ({
      id: truck.id,
      startDate: dateSelected[truck.id] ?? moment(new Date()).format('YYYY-MM-DD'),
      driverId: driverValue[truck.id] ? driverValue[truck.id].id : null
    }));
    console.log('truckSelected :>> ', truckSelected);
    TripStore.updateJobTrip(props.jobId, {
      trucks: truckSelected,
    });
    setIsDisabled(true);
    Swal.fire('แก้ไขข้อมูลสำเร็จ!', '', 'success');
    // navigate('/trips');
  };

  const selectDriverValue = (truckId: string, { items, value }: any) => {
    const driver = items.find((d: any) => d.id === value);
    setDriverValue((prevState: any) => ({ ...prevState, [truckId]: driver }));
    setIsDisabled(false)
  }

  const removeDriver = (truckId: string, tripId: string) => {
    Swal.fire({
      icon: 'warning',
      text: `ยืนยันการลบคนขับ!`,
      showCancelButton: true,
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        Swal.fire('ลบทริปสำเร็จ!', '', 'success');
        const newDriverValue = JSON.parse(JSON.stringify(driverValue));
        delete newDriverValue[truckId];
        setDriverValue(newDriverValue);
        TripStore.deleteDriver(tripId);
      }
    });
  }

  console.log('truckStore.loading :>> ', truckStore.loading);

  const jobDetail = TransportationStore.jobDetail ? JSON.parse(JSON.stringify(TransportationStore.jobDetail)) : {};
  console.log('jobDetail :>> ', jobDetail);

  if (!productTypes[jobDetail?.productTypeId]) return <LoadingBar color="#ffc107" ref={ref} />;

  if (TransportationStore.error_response) {
    return <h1>{TransportationStore.error_response.content}</h1>;
  }

  return (
    <Page>
      <PageHeader breadcrumbs={breadcrumbs}>{t('job.info')}</PageHeader>
      <ButtonGroup>
        <ButtonBack onClick={() => navigate('/trips')}>{t('back')}</ButtonBack>
        <ButtonConfrim onClick={onSubmit} isDisabled={isDisabled || !newTruckIds.length ? true : false}>
          {t('confirm')}
        </ButtonConfrim>
      </ButtonGroup>
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => setIsDragStart(true)}>
        <Grid layout="fluid" spacing="compact">
          <GridColumn medium={7}>
            <div style={LEFT_RIGHT_SPACING}>
              <div>
                <Box style={{ position: 'relative', overflow: 'hidden', marginTop: 5 }}>
                  <Collapse
                    isExpanded
                    topic={<Header text={'งานที่เลือก'} img={'box'} />}
                    children={
                      <Row style={LEFT_RIGHT_SPACING}>
                        <Col display={'flex'} flex={1} flexWrap={'wrap'}>
                          <div style={{ width: '100%' }}>
                            <Detail header={'ชื่อสินค้า'} content={jobDetail?.productName} />
                          </div>

                          <Col flex={1}>
                            <Detail
                              header={'ประเภท'}
                              content={productTypes[jobDetail?.productTypeId]}
                            />
                          </Col>
                          <Col flex={1}>
                            <Detail
                              header={'วันที่'}
                              content={
                                jobDetail?.loadingDatetime ? moment(jobDetail.loadingDatetime).format('DD/MM/YY') : '-'
                              }
                            />
                          </Col>
                          <Col flex={1}>
                            <Detail header={'ชื่อเจ้าของ'} content={jobDetail?.owner?.fullName} />
                          </Col>
                          <Col flex={1}>
                            <Detail header={'เบอร์โทรเจ้าของ'} content={formatPhoneNumber(jobDetail?.owner?.mobileNo)} />
                          </Col>

                          <div style={{ width: '100%' }}>
                            <Label>{'สถานที่ :'}</Label>
                            <Location header={'จาก'} content={jobDetail?.from?.name} img={'pinDrop'} />
                            {jobDetail?.to?.map((data: any, index: number) => (
                              <Location
                                key={`location-${index}`}
                                content={data?.name}
                                header={'ถึง'}
                                img={'pinDrop2'}
                              />
                            ))}
                          </div>

                          <Box style={VEHICLE_DETAIL_BOX}>
                            <div style={{ display: 'flex' }}>
                              <Detail
                                header={'ประเภทรถ'}
                                content={
                                  truckTypes[jobDetail?.truckType] ??
                                  (Object.keys(truckTypes)?.length ? '-' : <Spinner size="medium" />)
                                }
                                style={{ flex: 1 }}
                              />

                              <Detail
                                header={'จำนวนรถที่ต้องการ'}
                                content={`${jobDetail?.requiredTruckAmount ?? '-'} คัน`}
                                style={{ flex: 1, color: '#ffc107' }}
                              />

                              <Detail
                                header={'การลงสินค้า'}
                                content={jobDetail?.tipper ? 'ดั้มพ์' : 'ไม่ดั้มพ์'}
                                style={{ flex: 1 }}
                              />

                              <Detail header={'คอก'} content={'-'} style={{ flex: 1 }} />
                            </div>
                          </Box>
                        </Col>
                      </Row>
                    }
                  />
                </Box>

                <Box style={{ backgroundColor: '#ebeef3', position: 'relative', overflow: 'hidden' }}>
                  <Collapse
                    isExpanded
                    topic={<Header text={'รถที่เลือก'} img={'truck'} />}
                    children={
                      <div style={isDragStart ? DROP_BOX_SHOW : DROP_BOX_HIDE}>
                        {isDragStart && !state.truckSelected?.length && (
                          <div style={DROP_BOX_CONTENT}>
                            <TruckAnimate />
                          </div>
                        )}
                        <Droppable key={`truck-selected-droppable-1`} droppableId={truckSelectedDroppable.droppableId}>
                          {(provided: any, snapshot: any) => (
                            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                              {state[truckSelectedDroppable.listId] &&
                                state[truckSelectedDroppable.listId].map((item: any, index: number) => (
                                  <Draggable
                                    key={`${truckSelectedDroppable.listId}-${item.id}-${index}`}
                                    draggableId={`${truckSelectedDroppable.listId}-${item.id}-${index}`}
                                    index={index}
                                  >
                                    {(provided: any, snapshot: any) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                          paddingTop: 10,
                                          paddingBottom: 10,
                                          overflow: 'visible',
                                          ...(!item.old ? { border: '2px solid #ffc107' } : undefined),
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            paddingBottom: 5
                                          }}
                                        >
                                          {!item.old && (
                                            <div style={NEW_ICON}>
                                              {/* <New key={item.id} /> */}
                                              <NewText>{'ใหม่'}</NewText>
                                            </div>
                                          )}
                                          <div style={{ flex: 2, paddingRight: 15 }}>
                                            <Row style={{ marginBottom: 0 }}>
                                              <div>
                                                <Value style={{ marginBottom: 0 }}>
                                                  {item.registrationNumber ? item.registrationNumber.join(' / ') : '-'}
                                                </Value>
                                              </div>
                                            </Row>
                                            <Row style={{ marginBottom: 0 }}>
                                              <Col flex={1}>
                                                <Detail
                                                  header={'ประเภท'}
                                                  content={
                                                    truckTypes[item.truckType] ??
                                                    (Object.keys(truckTypes)?.length ? '-' : <Spinner size="medium" />)
                                                  }
                                                />
                                              </Col>
                                              <Col flex={1}>
                                                <Detail header={'ความสูงคอกรถ'} content={item.stallHeight ?? '-'} />
                                              </Col>
                                            </Row>
                                          </div>
                                          <div style={{ flex: 1, paddingLeft: 15, borderLeft: '1px solid #ebeef3' }}>
                                            <Label>{'วันที่เรื่มงาน :'}</Label>
                                            <DatePicker
                                              defaultValue={item?.startDate || new Date()}
                                              dateFormat="DD/MM/YYYY"
                                              onChange={(date) => {
                                                setIsDisabled(false);
                                                setDateSelected((prevState: any) => ({
                                                  ...prevState,
                                                  [item.id]: date,
                                                }));
                                              }}
                                            />
                                          </div>
                                          <span style={TRASH} onClick={() => removeTrip(item.id, item.tripId)}>
                                            <TrashIcon label={'trash-icon'} size={'small'} />
                                          </span>
                                        </div>
                                        <div style={{ display: 'flex', borderTop: '1px solid #ebeef3' }}>
                                          <Row style={{ flex: 2, paddingRight: 15 }}>
                                            <div style={{ width: '100%' }}>
                                              <Field label={t('drivers')} name="driverId">
                                                {({ fieldProps }: any) => {
                                                  return (
                                                    <Fragment>
                                                      <DriverSelector
                                                        maxWidth="100%"
                                                        // value={driverValue}
                                                        onSelect={(e) => { }}
                                                        currentValue={(val: any) => selectDriverValue(item.id, val)}
                                                        placeholder={t('drivers')}
                                                        noResultsMessage={t('noData')}
                                                      />
                                                    </Fragment>
                                                  );
                                                }}
                                              </Field>
                                            </div>
                                          </Row>
                                          <Row style={{ flex: 3, flexDirection: 'column' }}>
                                            {driverValue[item.id] && <div style={{ display: 'flex', paddingLeft: 15, backgroundColor: 'rgb(244 245 247)', borderRadius: 3, position: 'relative' }}>
                                              <Detail
                                                header={'ชื่อคนขับ'}
                                                content={driverValue[item.id]?.fullName ?? '-'}
                                                style={{ flex: 1 }}
                                              />
                                              <Detail
                                                header={'เบอร์โทร'}
                                                content={formatPhoneNumber(driverValue[item.id]?.phoneNumber) ?? '-'}
                                                style={{ flex: 1 }}
                                              />
                                              <span style={{ ...TRASH, paddingTop: 5, paddingRight: 5 }} onClick={() => removeDriver(item.id, item.tripId)}>
                                                <TrashIcon label={'trash-icon'} size={'small'} />
                                              </span>
                                            </div>}
                                          </Row>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    }
                  />
                </Box>
              </div>
            </div>
          </GridColumn>
          <GridColumn medium={5}>
            <div
              style={{
                ...LEFT_RIGHT_SPACING,
                maxHeight: 1290,
                ...(state.trucks?.length >= 2 ? { overflowX: 'scroll' } : undefined),
              }}
            >
              <Droppable
                key={`truck-droppable-1`}
                droppableId={truckDroppable.droppableId}
              // isDropDisabled={truckDroppable.droppable}
              >
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    style={{
                      ...getListStyle(snapshot.isDraggingOver),
                      border: '1px solid #cfcfcf',
                    }}
                  >
                    <TextInputSelected
                      options={truckTypeSelectedOption}
                      loadingSpinner={truckStore.loading}
                      onSubmit={truckDroppable.onSubmit}
                      onInputChange={onSelectedTruckOptions}
                    />
                    {/* <Form onSubmit={() => truckDroppable.onSubmit()}>
                      {({ formProps }: any) => (
                        <form {...formProps} style={{ paddingBottom: 20 }}>
                          <div style={{ position: 'relative' }}>
                            <span style={SEARCH_ICON}>
                              <SearchIcon label={'search-icon'} size={'medium'} />
                            </span>
                            <Field name={truckDroppable.title}>
                              {({ fieldProps }: any) => (
                                <Select
                                  {...fieldProps}
                                  value={truckTypeSelectedOption.filter((option: any) => {
                                    return option.value === selectedOption;
                                  })}
                                  options={truckTypeSelectedOption}
                                  onInputChange={(newValue: string) => setSearchTruck(newValue)}
                                  onChange={(e: any) => onSelectedTruckOptions(e)}
                                  placeholder={'Search trucks'}
                                  id={'truck-select-options'}
                                />
                              )}
                            </Field>
                            {(truckStore.loading || truckTypesStore.loading) && (
                              <span style={LOADING_ICON}>
                                <Spinner size={'small'} />
                              </span>
                            )}
                          </div>
                        </form>
                      )}
                    </Form> */}
                    <div>
                      {state[truckDroppable.listId] &&
                        state[truckDroppable.listId].map((item: any, index: number) => (
                          <Draggable
                            key={`${truckDroppable.listId}-${item.id}-${index}`}
                            draggableId={`${truckDroppable.listId}-${item.id}-${index}`}
                            index={index}
                          >
                            {(provided: any, snapshot: any) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <Row style={{ marginBottom: 0, paddingBottom: 5 }}>
                                  <div>
                                    <Value style={{ marginBottom: 0 }}>
                                      {item.registrationNumber ? item.registrationNumber.join(' / ') : '-'}
                                    </Value>
                                  </div>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                  <Col flex={1}>
                                    <DetailSmall
                                      header={'ประเภท'}
                                      content={
                                        truckTypes[item.truckType] ??
                                        (Object.keys(truckTypes)?.length ? '-' : <Spinner size="medium" />)
                                      }
                                    />
                                  </Col>
                                  <Col flex={1}>
                                    <DetailSmall header={'ความสูงคอกรถ'} content={item.stallHeight} />
                                  </Col>
                                </Row>
                                <Row
                                  style={{
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderTop: '1px dashed #ebeef3',
                                    margin: 0,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: snapshot.isDragging ? '#fff' : '#ffc107' }}>
                                      <ArrowLeftIcon label={'chevron-down'} size={'medium'} />
                                    </span>
                                    <ValueSmall
                                      style={{ color: snapshot.isDragging ? '#fff' : '#ffc107', paddingLeft: 5 }}
                                    >
                                      {'เลือกรถคันนี้'}
                                    </ValueSmall>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div>
                                      <span>
                                        <ValueSmall>{item.owner?.fullName}</ValueSmall>
                                      </span>
                                    </div>
                                    <div style={{ paddingLeft: 10 }}>
                                      <Image
                                        src={`${process.env.API_ENDPOINT}/api/v1/media/file-stream?attachCode=${item.owner?.avatar?.object}`}
                                        id={item.id}
                                        onError={onImageError}
                                      />
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </div>
                    {provided.placeholder}
                    {truckStore.truckList?.content && truckPage < (truckStore.truckList.totalPages ?? 0) && (
                      <div style={{ paddingBottom: 5, display: 'flex', justifyContent: 'center' }}>
                        <ButtonLoadMore onClick={() => loadMoreTruck(selectedOption ? +selectedOption : undefined)}>
                          <span style={{ width: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {!truckStore.loading ? <MoreIcon label={'more-icon'} size={'medium'} /> : <Dots />}
                          </span>
                        </ButtonLoadMore>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </div>
          </GridColumn>
        </Grid>
      </DragDropContext>
    </Page>
  );
});

export default TripsInfo;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const Col = styled.div<{ flex: number; display?: 'flex'; flexWrap?: 'wrap' }>`
  flex: ${(props) => props.flex | 1};
  display: ${(props) => props?.display ?? 'block'};
  flex-wrap: ${(props) => props?.flexWrap ?? 'nowrap'};
`;

const Box = styled.div`
  padding: 10px;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  margin-top: 15px;
`;

const Label = styled.p`
  color: #999;
  font-size: 13px;
  margin: 10px 0 10px 0;
`;

const Value = styled.p`
  font-size: 16px;
  margin: 10px 0 10px 0;
`;

const ValueSmall = styled.span`
  font-size: 14px;
  margin: 5px 0;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 15px;
  top: 50px;
`;

const ButtonBack = styled(Button)`
  border: 1px solid #ffc107 !important;
  color: #ffc107 !important;
  background-color: transparent !important;
  width: 100px !important;

  &:hover {
    color: white !important;
    background: #ffc107 !important;
  }
`;

const ButtonConfrim = styled(Button)`
  border: 1px solid #ffc107 !important;
  color: #000 !important;
  background-color: #ffc107 !important;
  width: 100px !important;
  margin-left: 15px !important;

  &:hover {
    color: #ffc107 !important;
    background: #fff !important;
  }
`;

const ButtonLoadMore = styled(Button)`
  border: 1px solid #cccccc !important;
  color: #000 !important;
  background-color: #cccccc !important;
  align-items: center;
`;

const NewText = styled.span`
  font-size: 10px;
  padding: 0 10px;
  background-color: #ffc107;
  border-radius: 3px;
  color: #fff;
`;
