import React, { useState, Fragment, useEffect, CSSProperties, useCallback, SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { CardBody } from '@paljs/ui/Card';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button/standard-button';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import { ic_person } from 'react-icons-kit/md/ic_person';
import { ic_phone } from 'react-icons-kit/md/ic_phone';
import { ic_lock } from 'react-icons-kit/md/ic_lock';
import { ic_email } from 'react-icons-kit/md/ic_email';
import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import UploadButton from '../../components/UploadButton';
import userApi, { CreateUserPayload, CreateUserResponse } from '../../services/user-api';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { AxiosResponse } from 'axios';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { useWindowSize, formatPhoneNumber } from '../../utils';
import { Property } from 'csstype';
import { useMst } from '../../stores/root-store';
import { UploadFilePath } from '../../services/upload-api';
import { Col, Row } from '../../Layouts/Controller/controller';
import { UserNonPersistStore } from '../../stores/user-non-persist-store';
import images from '../../components/Themes/images';
import SearchForm from '../../components/search-form';
import MoreIcon from '@atlaskit/icon/glyph/more';
import LottieView from 'react-lottie';
import { Radio } from '@atlaskit/radio';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import { DateFormat } from '../../components/simple-data';

interface Props { }

interface InputData {
  // confirmPassword: string;
  fullName: string;
  // password: string;
  phoneNumber: string;
  uploadFile?: string;
  email?: string;
  userType?: string;
}

interface CarrierData {
  id: string
  fullName: string
  phoneNumber: string
  email: string
  userType: string
  createdAt: string
  legalType: string
}

interface FullNameProps {
  isNoData?: boolean;
}

const ITEM_LABEL: CSSProperties = {
  margin: 0,
  fontSize: 14,
  flex: 1
}

const IMAGE_PROFILE: CSSProperties = {
  width: 120,
  height: 'auto',
};

const ITEM_BOX: CSSProperties = {
  margin: '10px 15px',
  backgroundColor: '#fff',
  borderRadius: 3,
  cursor: 'pointer'
}

const ShowFileName = styled.p`
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BackText = styled.span`
  color: #fbbc12;
`;

const SubmitText = styled.span`
  color: #000;
`;

const BottomStyled = {
  margin: '0 6px',
};

const BottomBackStyled = {
  ...BottomStyled,
  border: '1px solid #FBBC12',
  backgroundColor: 'transparent',
};

const BottomSubmitStyled = {
  ...BottomStyled,
  border: '1px solid #FBBC12',
  backgroundColor: '#FBBC12',
};

const startAdornmentIcon = (iconName: 'user' | 'phone' | 'password' | 'email') => {
  let icon = '';
  if (iconName === 'user') {
    icon = ic_person;
  } else if (iconName === 'phone') {
    icon = ic_phone;
  } else if (iconName === 'email') {
    icon = ic_email;
  } else {
    icon = ic_lock;
  }
  return <Icon icon={icon} style={{ paddingLeft: 6, color: 'gray' }} size={22} />;
};

const Item = ({ id, fullName, phoneNumber, email, value, t, onChange }: any) => (<Box
  flex={1}
  style={{
    ...ITEM_BOX,
    cursor: id === value ? 'not-allowed' : 'pointer'
  }}
  onClick={() => id === value ? null : onChange({ currentTarget: { value: id } })}
>
  <Row justifyContent="flex-start" alignItem={'center'}>
    <FieldWrapperBox style={ITEM_LABEL}>
      <Label>{t('companyName')} :</Label>
      <ReadViewContainer data-testid="carrierCompanyName">
        {fullName || <div style={{ color: '#aaa' }}>{'-'}</div>}
      </ReadViewContainer>
    </FieldWrapperBox>
    <Radio
      isChecked={id === value}
      style={{ cursor: id === value ? 'not-allowed' : 'pointer' }}
      // onChange={onChange}
      css={{}}
      name={`${id}-${fullName}`}
      value={id}
      aria-labelledby={`row-${id}-carrier row-${id}-updated`}
    />
  </Row>
  <Row justifyContent="flex-start">
    <FieldWrapperBox style={ITEM_LABEL}>
      <Label>{t('phoneNumber')} :</Label>
      <ReadViewContainer data-testid="carrierPhoneNumber">
        {formatPhoneNumber(phoneNumber) || <div style={{ color: '#aaa' }}>{'-'}</div>}
      </ReadViewContainer>
    </FieldWrapperBox>
    <FieldWrapperBox style={ITEM_LABEL}>
      <Label>{t('email')} :</Label>
      <ReadViewContainer data-testid="carrierEmail">
        {email || <div style={{ color: '#aaa' }}>{'-'}</div>}
      </ReadViewContainer>
    </FieldWrapperBox>
  </Row>
</Box>)

const Dots = () => (
  <LottieView
    options={{
      autoplay: true,
      loop: true,
      animationData: require('../../images/animations/dots-loading.json'),
    }}
    width={60}
    height={40}
  />
);

const AddDriver: React.FC<Props> = observer(() => {
  const { uploadFileStore, loginStore } = useMst();
  const { t } = useTranslation();

  const [file, setFile] = useState<File>();
  const [isUploading, setIsUploading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [legalType, setLegalType] = useState<'INDIVIDUAL' | 'JURISTIC'>('INDIVIDUAL');
  const [userType, setUserType] = useState<'SHIPPER' | 'CARRIER' | 'BOTH'>('SHIPPER');
  const [attachCodes, setAttachCodes] = useState<string[]>([]);
  const [width] = useWindowSize();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState({});
  const [value, setValue] = useState<string>('1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [carrierProfile, setCarrierProfile] = useState<CarrierData>();
  const [previewImage, setPreviewImage] = useState<any>(null);

  // const onSubmit = data => console.log(data);

  const breadcrumbs = (
    <Breadcrumbs onExpand={() => { }}>
      <BreadcrumbsItem onClick={() => navigate(-1)} text={t('driverManagement')} key="driver-management" />
      <BreadcrumbsItem text={t('addNewDriver')} key="driver-info" />
    </Breadcrumbs>
  );

  useEffect(() => {
    uploadFileStore.clear();
    return () => {
      setPhoneError(false);
      setPhoneNumber('');
      UserNonPersistStore.clear();
    };
  }, []);

  useEffect(() => {
    const tmpProfile = JSON.parse(JSON.stringify(UserNonPersistStore.userData));
    console.log('tmpProfile :>> ', tmpProfile);
    if (tmpProfile && tmpProfile.content) {
      setIsLoading(false);
    }
  }, [JSON.stringify(UserNonPersistStore.userData)]);

  const handleSubmit = (formState: InputData) => {
    Swal.fire({
      icon: 'info',
      text: t('confirmForCreateDriver'),
      showCancelButton: true,
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        const payload: CreateUserPayload = {
          fullName: formState.fullName,
          email: formState.email,
          userType,
          phoneNumber: `+66${phoneNumber.substr(1)}`,
          legalType,
          url: attachCodes,
          parentCarrierId: value
        };
        console.log('Payload :: ', payload);
        const MODAL_TIMEOUT = 1000;
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
            userApi
              .createUser(payload)
              .then((response) => {
                if (response && response.status < 300) {
                  Swal.hideLoading();
                  const data = (response as AxiosResponse<CreateUserResponse>).data;
                  console.log('edit user response', data);
                  Swal.update({
                    icon: 'success',
                    titleText: '',
                    text: t('createUserSuccess'),
                    showConfirmButton: false,
                  });
                  return setTimeout(() => {
                    Swal.close();
                    navigate('/drivers');
                  }, MODAL_TIMEOUT);
                } else {
                  Swal.fire({
                    icon: 'error',
                    text: t('createUserErrorByUser'),
                  });
                  console.error('create user: user error', response);
                }
              })
              .catch((error) => {
                console.error('Error while create the user', error);
                Swal.fire({
                  icon: 'error',
                  text: 'Error while create this user',
                });
              });
          },
        });
      }
    });
  };

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setFile(file);
      setIsUploading(true);
      uploadFileStore.uploadFile(UploadFilePath.USER_DOC, file);
    }
  };

  const validatePhoneNumber = (value?: string) => {
    if (value && value.startsWith('0')) value = `+66${value.substr(1)}`;
    const regex = /^\+?([0-9]{2})\)??([0-9]{9})$/;
    return value ? (regex.test(value) ? undefined : 'INVALID_PHONE_NUMBER') : undefined;
  };

  const isDisabled = phoneError || !phoneNumber.length ? true : false;

  useEffect(() => {
    if (phoneNumber) {
      const isInvalid = validatePhoneNumber(`+66${phoneNumber.substr(1)}`);
      setPhoneError(isInvalid ? true : false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    const newFile = JSON.parse(JSON.stringify(uploadFileStore.file));
    const isNoFile = newFile == null || Object.keys(newFile).every((key) => newFile[key] == null);
    if (!isNoFile) {
      setAttachCodes([...attachCodes, newFile.attachCode]);
      setIsUploading(false);
      uploadFileStore.clear();
    }
  }, [uploadFileStore.file]);

  const setInitialSortingAndPage = () => {
    const options = { page: 1, descending: true, userType: 'CARRIER' };
    setSearchValue(options);
    UserNonPersistStore.getUsersV2(options, true);
  };

  const onSearch = (value: string) => {
    console.log('value :>> ', value);
    if (value) {
      let search = {
        page: 1,
        textSearch: value,
        descending: true,
        userType: 'CARRIER'
      };
      setPage(1);
      setSearchValue(search);
      UserNonPersistStore.getUsersV2(search, true);
    } else {
      setPage(1);
      setInitialSortingAndPage();
    }
  }

  const onLoadMore = (page: number) => {
    let search = {
      ...searchValue,
      page
    }
    setPage(page);
    setSearchValue(search);
    UserNonPersistStore.getUsersV2(search, true);
  }

  const onSelectChoice = useCallback(
    ({ currentTarget: { value } }: SyntheticEvent<any>) => {
      const newCarrier = JSON.parse(JSON.stringify(UserNonPersistStore.userData.content))?.find((data: any) => data.id === value);
      if (Object.keys(newCarrier).length) {
        setCarrierProfile({
          id: newCarrier.id,
          fullName: newCarrier.fullName,
          phoneNumber: newCarrier.phoneNumber,
          email: newCarrier.email,
          userType: newCarrier.userType,
          createdAt: newCarrier.createdAt,
          legalType: newCarrier.legalType
        });
        if (newCarrier?.avatar) {
          setPreviewImage(newCarrier.avartar)
        }
        setValue(value);
      }
      setIsLoading(true);
    },
    [setValue],
  );

  return (
    <div>
      <PageHeader breadcrumbs={breadcrumbs}>{t('addNewDriver')}</PageHeader>


      <Row>
        <Col flex={1} style={{ padding: '0 10px' }}>
          <Form onSubmit={handleSubmit}>
            {({ formProps }) => (
              <form {...formProps} name="add-user" style={{ overflow: 'hidden' }}>
                <Row style={{ flexDirection: 'column' }}>
                  <Col>
                    <Field
                      label={`${t('fullName')}`}
                      isRequired
                      name="fullName"
                      // validate={validate}
                      defaultValue=""
                    >
                      {({ fieldProps, error, meta: { valid } }: any) => (
                        <Fragment>
                          <Textfield
                            {...fieldProps}
                            elemBeforeInput={startAdornmentIcon('user')}
                            placeholder={`${t('fullName')}`}
                            testId="nameField"
                          />
                          {error === 'INCORRECT_PHRASE' && (
                            <ErrorMessage>Incorrect, try &lsquo;open sesame&rsquo;</ErrorMessage>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field
                      label={t('phoneNumber')}
                      isRequired
                      name="phoneNumber"
                      // validate={validate}
                      defaultValue=""
                    >
                      {({ fieldProps, error, meta: { valid } }: any) => (
                        <Fragment>
                          <Textfield
                            {...fieldProps}
                            elemBeforeInput={startAdornmentIcon('phone')}
                            placeholder={t('phoneNumber')}
                            value={phoneNumber}
                            onChange={(e: any) => setPhoneNumber(e.target.value)}
                            style={{ width: '100%' }}
                            testId="phoneNumberField"
                          />
                          {phoneError && <ErrorMessage>{t('invalidPhoneNumber')}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field label={t('email')} name="email">
                      {({ fieldProps, error, meta: { valid } }: any) => (
                        <div style={{ display: 'flex' }}>
                          <Textfield
                            {...fieldProps}
                            elemBeforeInput={startAdornmentIcon('email')}
                            placeholder={t('email')}
                            testId="emailField"
                          />
                        </div>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <div style={{ marginTop: 20 }}>
                      <Field
                        label={`${t('citizenId')}`}
                        name="uploadFile"
                        // validate={validate}
                        defaultValue=""
                      >
                        {({ fieldProps, error, meta: { valid } }: any) => (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <UploadButton accept=".pdf" onChange={handleUploadFile} isLoading={uploadFileStore.loading} />
                            <ShowFileName>{(!isUploading && file?.name) || ''}</ShowFileName>
                          </div>
                        )}
                      </Field>
                    </div>
                  </Col>
                </Row>

                {carrierProfile && (<div style={{ marginTop: 30 }}>
                  <HeaderBox style={{ paddingLeft: 0 }}>
                    <img src={images['userIcon']} style={{ width: 15, height: 15, paddingRight: 10 }} />
                    <Topic>{t('carOwner')}</Topic>
                  </HeaderBox>

                  <Row style={{
                    marginRight: 20,
                    marginTop: 10,
                    border: '2px solid #ddd',
                    borderRadius: 5,
                    padding: '5px 0 15px'
                  }}>
                    <Col
                      flex={1}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ImageFram>
                        <ImagePreview>
                          {previewImage ? (
                            <img src={previewImage} alt="" style={IMAGE_PROFILE} />
                          ) : (
                            <Icon icon={ic_person} size={50} />
                          )}
                        </ImagePreview>
                      </ImageFram>
                    </Col>

                    <Col flex={3}>
                      <FieldWrapperBox>
                        <ReadViewContainer data-testid="userFullname" style={{ paddingLeft: 0 }}>
                          <Name isNoData={!carrierProfile?.fullName}>{carrierProfile?.fullName || t('fullNamePlaceholder')}</Name>
                        </ReadViewContainer>
                      </FieldWrapperBox>

                      <FieldWrapperBox>
                        <Label>{t('memberSince')} :</Label>
                        <ReadViewContainer data-testid="memberSince">
                          {DateFormat(carrierProfile?.createdAt as string, loginStore.language)}
                        </ReadViewContainer>
                      </FieldWrapperBox>

                      <FieldWrapperBox>
                        <Label>{t('legalType')} :</Label>
                        <ReadViewContainer data-testid="legalTypeField">
                          {carrierProfile?.legalType === 'INDIVIDUAL' ? t('individual') : t('company')}
                        </ReadViewContainer>
                      </FieldWrapperBox>

                      <FieldWrapperBox>
                        <Label>{t('userType')} :</Label>
                        <ReadViewContainer data-testid="userTypeField">
                          {carrierProfile?.userType ? t(`userTypeGroup.${carrierProfile?.userType}`) : '-'}
                        </ReadViewContainer>
                      </FieldWrapperBox>

                      <FieldWrapperBox>
                        <Label>{t('phoneNumber')} :</Label>
                        <ReadViewContainer data-testid="phoneNumberField">
                          {carrierProfile?.phoneNumber || <div style={{ color: '#aaa' }}>{'-'}</div>}
                        </ReadViewContainer>
                      </FieldWrapperBox>

                      <FieldWrapperBox>
                        <Label>{t('email')} :</Label>
                        <ReadViewContainer data-testid="emailField">
                          {carrierProfile?.email || <div style={{ color: '#aaa' }}>{'-'}</div>}
                        </ReadViewContainer>
                      </FieldWrapperBox>
                    </Col>
                  </Row>
                </div>)}

                <Row style={{ justifyContent: 'flex-end', margin: '0 20px 0 0' }}>
                  <FormFooter>
                    <Button type="button" style={BottomBackStyled} onClick={() => navigate('/drivers')} testId="backButton">
                      <BackText>{t('back')}</BackText>
                    </Button>
                    <Button
                      type="submit"
                      isDisabled={isDisabled}
                      style={
                        isDisabled
                          ? {
                            ...BottomSubmitStyled,
                            backgroundColor: '#D8D8D8',
                            border: 'none',
                          }
                          : BottomSubmitStyled
                      }
                      testId="submitButton"
                    >
                      <SubmitText>{t('confirm')}</SubmitText>
                    </Button>
                  </FormFooter>
                </Row>
              </form>
            )}
          </Form>
        </Col>

        <Col flex={1} style={{ padding: '0 10px' }}>

          <HeaderBox style={{ paddingLeft: 0 }}>
            {/* <img src={images['questionMark']} style={{ width: 15, height: 15 }} /> */}
            <Topic>{t('searchCarrier')}</Topic>
          </HeaderBox>

          <SearchForm
            style={{
              width: '100%',
              paddingTop: 5
            }}
            onSearch={onSearch}
          />

          <ItemBox>
            {UserNonPersistStore?.userData?.content?.map((user: any, index: number) => (
              <Item
                key={`carrier-${index}`}
                id={user.id}
                value={value}
                fullName={user.fullName}
                phoneNumber={user.phoneNumber}
                email={user.email}
                t={t}
                onChange={onSelectChoice}
              />
            ))}

            {UserNonPersistStore.userData?.content?.length > 0 && page < (UserNonPersistStore.pagination.totalPages ?? 0) && (
              <div style={{ paddingBottom: 5, display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <ButtonLoadMore onClick={() => onLoadMore(page + 1)}>
                  <span style={{ width: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {!UserNonPersistStore.loading ? <MoreIcon label={'more-icon'} size={'medium'} /> : <Dots />}
                  </span>
                </ButtonLoadMore>
              </div>
            )}
          </ItemBox>
        </Col>
      </Row>
    </div>
  );
});

export default AddDriver;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailLabel = styled.div`
  margin-top: 8px;
  color: #aaa;
`;

const EditViewContainer = styled.div`
  z-index: 300;
  position: relative;
  width: 200px;
`;

const ReadViewContainer = styled.div`
  display: flex;
  max-width: 100%;
  padding: 5px ${gridSize() - 2}px;
  word-break: break-word;
`;

const Box = styled(Col)`
  padding: 15px;
`

const FieldWrapperBox = styled(FieldWrapper)`
  margin-top: 8px;
`

const Label = styled(DetailLabel)`
  margin-top: 0 !important;
`

const HeaderBox = styled.div`
  padding-left: 15px;
  margin-top: 8px;
  display: flex;
  align-items: center;
`

const Topic = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #6B778C;
`

const ItemBox = styled.div`
  margin: 0 3px;
  border-radius: 5px;
  max-height: 650px;
  overflow: auto;
  background-color: #ededed;
`

const ButtonLoadMore = styled(Button)`
  border: 1px solid #cccccc !important;
  color: #000 !important;
  background-color: #cccccc !important;
  align-items: center;
`;

const ImageFram = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ImagePreview = styled.div`
  width: 80px;
  height: 80px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
`;

const Name = styled.p<FullNameProps>`
  color: ${({ isNoData }) => (isNoData ? '#AAA' : 'inherit')};
  margin: 0 0 10px 0;
  font-size: 1.125rem;
  font-weight: 700;

  :first-letter {
    text-transform: capitalize;
  }
`;
