import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select, { OptionType, ValueType } from '@atlaskit/select';
import { toFirstLetterUpperCase } from '../../utils';
import { observer } from "mobx-react-lite";
import { useMst } from '../../stores/root-store';

interface SelectorProps {
  placeholder?: string;
  onSelect: (value: string) => any;
  maxWidth?: string;
  includeNone?: boolean;
  noResultsMessage?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  fromMyTruckType?: number[]
  isSearchable?: boolean
}

const TruckTypesSelector = observer((props: SelectorProps) => {
  const { fromMyTruckType, placeholder, includeNone, maxWidth, onSelect, noResultsMessage,
    isRequired, isInvalid, isSearchable = true } = props
  const [options, setoptions] = useState<Array<any>>([])
  const { versatileStore } = useMst();

  const onSelected = (selectingOption: ValueType<OptionType, false>) => {
    if (
      includeNone &&
      options.findIndex((option) => option.label === selectingOption?.label) === 0
    ) {
      return onSelect('none');
    }
    return onSelect(selectingOption?.value + '' || 'none');
  };

  useEffect(() => {
    const listDropdown = JSON.parse(JSON.stringify(versatileStore.listDropdown))
    const listItems = JSON.parse(JSON.stringify(versatileStore.list))
    if (listDropdown) {
      if (fromMyTruckType && fromMyTruckType?.length) {
        const newFilter = [{ label: toFirstLetterUpperCase(placeholder || 'Select'), value: 0 }]
        listDropdown.forEach((e: any) => {
          if (fromMyTruckType.includes(e.value)) newFilter.push(e)
        })
        setoptions(newFilter)
      } else {
        const tmpOptions = [{ label: toFirstLetterUpperCase(placeholder || 'Select'), value: 0 }, ...listDropdown]
        setoptions(tmpOptions)
      }
    }
  }, [JSON.stringify(versatileStore.listDropdown)])

  return (
    <Selecty
      maxWidth={maxWidth}
      defaultOptions
      options={options}
      placeholder={placeholder ? toFirstLetterUpperCase(placeholder) : undefined}
      onChange={onSelected}
      isSearchable={isSearchable}
      noOptionsMessage={() => noResultsMessage || 'no results'}
      isRequired={isRequired}
      isInvalid={isInvalid}
    />
  );
})

interface SelectStyleProps {
  maxWidth?: string;
}
const Selecty = styled(Select) <SelectStyleProps>`
  min-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '300px')};
`;
export default TruckTypesSelector
