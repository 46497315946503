import { CSSProperties } from "react";
import { color } from "../../theme";

const WIDTH_97: CSSProperties = { width: "99%" }
const WIDTH_90: CSSProperties = { width: "95%" }

export const FLEX_ROW: CSSProperties = {
  display: 'flex',
  flexDirection: "row"
}
const PADDING_TOP10: CSSProperties = {
  paddingTop: 10
}

export const PADDING0: CSSProperties = { padding: '0px 0px 0px 0px' }
export const MARGIN0: CSSProperties = { margin: '0px 0px 0px 0px' }
export const OVER_FLOW_HIDDEN: CSSProperties = { overflow: 'hidden' }
export const MIN_HEIGHT_CONTAINER: CSSProperties = { minHeight: 500 }
export const HEAD_TEXT: CSSProperties = { fontWeight: 'bold', fontSize: 24 }

export const LIST_CONTAINER: CSSProperties = {
  ...WIDTH_97,
  minHeight: 120,
  margin: "10px 0px 0px 0px",
  border: `1px solid ${color.line}`,
  borderRadius: 5
}

export const IMG_BACKGROUND: CSSProperties = {
  maxHeight: "100%",
  maxWidth: "50%",
  position: 'absolute',
  right: 22.5,
  top: 10,
  zIndex: -1,
  opacity: 0.3
}
// export const IMG_BACKGROUND: CSSProperties = {
//   maxHeight: "100%",
//   maxWidth: "50%",
//   position: 'absolute',
//   right: 22.5,
//   top: 10,
//   zIndex: -1,
// }
export const PICKUP_POINT: CSSProperties = {
  ...WIDTH_90, ...FLEX_ROW, ...PADDING_TOP10
}
export const DELIVER_POINT: CSSProperties = {
  ...WIDTH_90, ...FLEX_ROW
}
export const COLOR_PRIMARY: CSSProperties = { color: color.primary }
export const COLOR_SUCCESS: CSSProperties = { color: color.success }
export const COLOR_WHITE: CSSProperties = { color: color.textWhite }
export const FONT_SIZE_14: CSSProperties = { fontSize: 14 }
export const CSS_WRAPER_TEXT: CSSProperties = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ...FONT_SIZE_14,
  fontWeight: 'bold'
}
export const MARGIN_LEFT_DEL10: CSSProperties = { marginLeft: -10 }
export const TRUCK_COUNT_CONTAINER: CSSProperties = {
  ...FLEX_ROW,
  width: '58%'
}
export const DIV_TRUCK_TYPE: CSSProperties = { ...FONT_SIZE_14 }
export const DIV_COUNT_TRUCK: CSSProperties = { ...FONT_SIZE_14 }
export const PRICE_LABEL: CSSProperties = {
  position: 'absolute',
  right: 22.5,
  bottom: 0,
  backgroundColor: color.blue,
  borderTopLeftRadius: 42 / 2,
  borderBottomLeftRadius: 42 / 2,
  padding: "5px 13px 5px 13px",
  marginBottom: 7,
}
