import AbstractAsyncSelector from '../../dropdowns/async.abstract';
import { UserApi } from '../../../services';

export default class DriverSelector extends AbstractAsyncSelector {
  fetch = async (search: string) => {
    try {
      const response = await UserApi.getUsersListV2({
        rowsPerPage: 10,
        descending: true,
        textSearch: search,
        roleId: 3
      });
      if (response && response.ok) {
        const users = response.data.data;
        const usersOptions = users.map((user: any) => ({
          label: user.fullName || user.email || user.phoneNumber || user.id,
          value: user.id,
        }));
        this.setState({ options: usersOptions, items: users });
      } else console.error('search users not ok', response);
    } catch (error) {
      console.error('error when search users', error);
    }
  };

}
