import React, { useState, useEffect } from 'react';
import { SectionHeader } from '../../theme/typography';
import { useTranslation } from 'react-i18next';
import UploadButton from '../../components/UploadButton';
import { ListFile } from '../../components/list-file/list-file';
import Swal from 'sweetalert2';
import { Row, Col } from '../../Layouts/Controller/controller';
import styled from 'styled-components';
import InlineEdit from '@atlaskit/inline-edit';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import Select, { ValueType } from '@atlaskit/select';
import { DocumentStatus, UserStore } from '../../stores/user-store';
import { UserApi } from '../../services';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import FilePreviewer from 'react-file-previewer';
import { useMst } from '../../stores/root-store';
import { observer } from 'mobx-react-lite';
import { UserNonPersistStore } from '../../stores/user-non-persist-store';
export interface UploadData {
  attachCode: string | null;
  token: string | null;
  fileName: string | null;
  type: string | null;
  status: string | null;
  fileUrl: string | null;
  fileType: string | null;
  uploadedDate: string | null;
}

export interface StateFileObject {
  fileName: string | null;
  url: string | null;
  type: string | null;
  attachCode?: string | null;
}

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailLabel = styled.div`
  margin-top: 8px;
  color: #aaa;
`;

const EditViewContainer = styled.div`
  z-index: 300;
  position: relative;
  width: 200px;
`;

const ReadViewContainer = styled.div`
  display: flex;
  max-width: 100%;
  padding: 5px ${gridSize() - 2}px;
  word-break: break-word;
`;

const MARGIN_TOP_10: React.CSSProperties = {
  marginTop: 10,
};

const UserDoc = observer((props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { userStore, loginStore } = useMst();

  const { t } = useTranslation();
  const { userData, handleDeleteFile, onUploadDocument } = props;

  const [fileClick, setfileClick] = useState<StateFileObject | null>(null);
  console.log("FILE CLICK :: ", fileClick)
  const statusOptions = [
    {
      label: t('docStatus:noDocument'),
      value: DocumentStatus.NO_DOCUMENT,
    },
    {
      label: t('docStatus:waitForVerify'),
      value: DocumentStatus.WAIT_FOR_VERIFIED,
    },
    {
      label: t('docStatus:verified'),
      value: DocumentStatus.VERIFIED,
    },
    {
      label: t('docStatus:rejected'),
      value: DocumentStatus.REJECTED,
    },
  ];

  const handleChangeDocStatus = (status: DocumentStatus) => {
    UserNonPersistStore.updateUserDocument(userData.userId, { status });
  };

  const processIsPdf = (fileObject: StateFileObject | null) => {
    if(!fileObject) return ''
    if(fileObject.type?.includes("pdf")) return `https://cors-anywhere.herokuapp.com/${fileObject.url}`
    return fileObject.url
  }

  const [listUpload, setlistUpload] = useState<Array<StateFileObject>>([]);
  console.log('User data files :: ', userData);
  return (
    <Col>
      <SectionHeader>{t('userDoc')}</SectionHeader>

      {userData.files.length ? (
        userData.files.map((file: StateFileObject) => {
          const fileName = typeof file != 'string' ? file.fileName : file;
          const date = '';
          return (
            <div key={fileName}>
              <ListFile
                fileName={fileName || ''}
                date={date}
                handlePreview={(attachCode) => {
                  setfileClick(file);
                  setIsOpen(true);
                }}
                handleDelete={() => {
                  const red = '#E03616';
                  const blue = '#3085D6';
                  Swal.mixin({
                    iconColor: red,
                    confirmButtonColor: red,
                    cancelButtonColor: blue,
                    confirmButtonText: t('delete'),
                    cancelButtonText: t('cancel'),
                  })
                    .fire({
                      title: t('deleteConfirmAlertTitle'),
                      titleText: t('deleteConfirmAlertText'),
                      icon: 'warning',
                      showCancelButton: true,
                    })
                    .then(({ isConfirmed }) => isConfirmed && handleDeleteFile(userData.userId, file.attachCode));
                }}
              />
            </div>
          );
        })
      ) : (
        <span>{t('noDocuments')}</span>
      )}

      <Row justifyContent={'space-between'} alignItem={'center'}>
        {userData && userData.documentStatus && (
          <FieldWrapper>
            <DetailLabel>{t('status')} :</DetailLabel>
            <InlineEdit
              defaultValue={userData.documentStatus}
              editView={({ errorMessage, ...fieldProps }) => (
                <EditViewContainer>
                  <Select {...fieldProps} options={statusOptions} autoFocus openMenuOnFocus />
                </EditViewContainer>
              )}
              readView={() => (
                <ReadViewContainer data-testid="docStatusField">
                  {statusOptions.filter((e) => e.value == userData.documentStatus)[0].label}
                </ReadViewContainer>
              )}
              onConfirm={(value) => {
                try {
                  const status = value;
                  handleChangeDocStatus(status.value);
                } catch (error) {
                  console.error('Error casting document status change (maybe invalid status)', error);
                }
              }}
            />
          </FieldWrapper>
        )}

        <UploadButton
          containerStyles={MARGIN_TOP_10}
          isLoading={false}
          accept=".pdf,.png,.jpg,.jpeg"
          onChange={onUploadDocument}
        />
      </Row>

      <ModalTransition>
        {isOpen && (
          <ModalDialog
            actions={[
              { text: 'ปิด', onClick: () => setIsOpen(false) },
              // { text: 'Skip' },
            ]}
            onClose={() => {
              setIsOpen(false);
            }}
            heading="แสดงตัวอย่างไฟล์"
            width={'x-large'}
          >
            <FilePreviewer
              file={{
                url: processIsPdf(fileClick)
              }}
            />
          </ModalDialog>
        )}
      </ModalTransition>
    </Col>
  );
});

export default UserDoc;
