// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-register-tsx": () => import("./../../../src/pages/auth/register.tsx" /* webpackChunkName: "component---src-pages-auth-register-tsx" */),
  "component---src-pages-auth-request-password-tsx": () => import("./../../../src/pages/auth/request-password.tsx" /* webpackChunkName: "component---src-pages-auth-request-password-tsx" */),
  "component---src-pages-auth-reset-password-tsx": () => import("./../../../src/pages/auth/reset-password.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-tsx" */),
  "component---src-pages-booking-index-tsx": () => import("./../../../src/pages/booking/index.tsx" /* webpackChunkName: "component---src-pages-booking-index-tsx" */),
  "component---src-pages-call-history-index-tsx": () => import("./../../../src/pages/call-history/index.tsx" /* webpackChunkName: "component---src-pages-call-history-index-tsx" */),
  "component---src-pages-carrier-account-tsx": () => import("./../../../src/pages/carrier-account.tsx" /* webpackChunkName: "component---src-pages-carrier-account-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-drivers-add-tsx": () => import("./../../../src/pages/drivers/add.tsx" /* webpackChunkName: "component---src-pages-drivers-add-tsx" */),
  "component---src-pages-drivers-index-tsx": () => import("./../../../src/pages/drivers/index.tsx" /* webpackChunkName: "component---src-pages-drivers-index-tsx" */),
  "component---src-pages-drivers-user-id-tsx": () => import("./../../../src/pages/drivers/[userId].tsx" /* webpackChunkName: "component---src-pages-drivers-user-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-detail-tsx": () => import("./../../../src/pages/job-detail.tsx" /* webpackChunkName: "component---src-pages-job-detail-tsx" */),
  "component---src-pages-job-status-tsx": () => import("./../../../src/pages/job-status.tsx" /* webpackChunkName: "component---src-pages-job-status-tsx" */),
  "component---src-pages-jobs-add-tsx": () => import("./../../../src/pages/jobs/add.tsx" /* webpackChunkName: "component---src-pages-jobs-add-tsx" */),
  "component---src-pages-jobs-index-tsx": () => import("./../../../src/pages/jobs/index.tsx" /* webpackChunkName: "component---src-pages-jobs-index-tsx" */),
  "component---src-pages-jobs-job-id-tsx": () => import("./../../../src/pages/jobs/[jobId].tsx" /* webpackChunkName: "component---src-pages-jobs-job-id-tsx" */),
  "component---src-pages-jobs-liff-search-tsx": () => import("./../../../src/pages/jobs/liff-search.tsx" /* webpackChunkName: "component---src-pages-jobs-liff-search-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-price-calculator-index-tsx": () => import("./../../../src/pages/price-calculator/index.tsx" /* webpackChunkName: "component---src-pages-price-calculator-index-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-shipper-account-tsx": () => import("./../../../src/pages/shipper-account.tsx" /* webpackChunkName: "component---src-pages-shipper-account-tsx" */),
  "component---src-pages-test-map-tsx": () => import("./../../../src/pages/test-map.tsx" /* webpackChunkName: "component---src-pages-test-map-tsx" */),
  "component---src-pages-trips-add-backup-tsx": () => import("./../../../src/pages/trips/add-backup.tsx" /* webpackChunkName: "component---src-pages-trips-add-backup-tsx" */),
  "component---src-pages-trips-add-tsx": () => import("./../../../src/pages/trips/add.tsx" /* webpackChunkName: "component---src-pages-trips-add-tsx" */),
  "component---src-pages-trips-index-tsx": () => import("./../../../src/pages/trips/index.tsx" /* webpackChunkName: "component---src-pages-trips-index-tsx" */),
  "component---src-pages-trips-job-id-tsx": () => import("./../../../src/pages/trips/[jobId].tsx" /* webpackChunkName: "component---src-pages-trips-job-id-tsx" */),
  "component---src-pages-trips-shipment-trip-id-tsx": () => import("./../../../src/pages/trips/shipment/[tripId].tsx" /* webpackChunkName: "component---src-pages-trips-shipment-trip-id-tsx" */),
  "component---src-pages-truck-detail-tsx": () => import("./../../../src/pages/truck-detail.tsx" /* webpackChunkName: "component---src-pages-truck-detail-tsx" */),
  "component---src-pages-user-upload-tsx": () => import("./../../../src/pages/user/upload.tsx" /* webpackChunkName: "component---src-pages-user-upload-tsx" */),
  "component---src-pages-users-add-tsx": () => import("./../../../src/pages/users/add.tsx" /* webpackChunkName: "component---src-pages-users-add-tsx" */),
  "component---src-pages-users-index-backup-tsx": () => import("./../../../src/pages/users/index_backup.tsx" /* webpackChunkName: "component---src-pages-users-index-backup-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */),
  "component---src-pages-users-user-doc-tsx": () => import("./../../../src/pages/users/user-doc.tsx" /* webpackChunkName: "component---src-pages-users-user-doc-tsx" */),
  "component---src-pages-users-user-id-tsx": () => import("./../../../src/pages/users/[userId].tsx" /* webpackChunkName: "component---src-pages-users-user-id-tsx" */),
  "component---src-pages-vehicles-add-tsx": () => import("./../../../src/pages/vehicles/add.tsx" /* webpackChunkName: "component---src-pages-vehicles-add-tsx" */),
  "component---src-pages-vehicles-index-tsx": () => import("./../../../src/pages/vehicles/index.tsx" /* webpackChunkName: "component---src-pages-vehicles-index-tsx" */),
  "component---src-pages-vehicles-truck-id-tsx": () => import("./../../../src/pages/vehicles/[truckId].tsx" /* webpackChunkName: "component---src-pages-vehicles-truck-id-tsx" */),
  "component---src-pages-view-job-id-tsx": () => import("./../../../src/pages/view/[jobId].tsx" /* webpackChunkName: "component---src-pages-view-job-id-tsx" */),
  "component---src-pages-view-success-tsx": () => import("./../../../src/pages/view/success.tsx" /* webpackChunkName: "component---src-pages-view-success-tsx" */)
}

