import React from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import { TFunction } from 'react-i18next';
import { UserApi } from '../../../services';
import InlineMessage from '@atlaskit/inline-message';
import ReactDOMServer from 'react-dom/server';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import moment from 'moment';
import { HeadType } from '@atlaskit/dynamic-table/dist/types/types';
import images from '../../Themes/images';
import MobileIcon from '@atlaskit/icon/glyph/mobile';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { formatPhoneNumber } from '../../../utils';

export const sortabled: any = {
  phoneNumber: true, //! Note that: DESC = true, ASC = fasle
  fullName: true,
  registerDate: true,
  jobCount: true,
  truckCount: true,
};

const IconWrapper = styled.div`
  margin: 0 2px;
  display: flex;
  justify-content: center;
  span {
    cursor: pointer;
  }
`;

const StatusText = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#00B132' : '#E03616')};
  text-transform: capitalize;
`;

export const createHead = (withWidth: boolean): HeadType => {
  return {
    cells: [
      {
        key: 'fullName',
        content: 'Full name',
        isSortable: true,
        // width: withWidth ? '120px' : undefined,
      },
      {
        key: 'phoneNumber',
        content: 'Phone number',
        shouldTruncate: true,
        isSortable: true,
        // width: withWidth ? '120px' : undefined,
      },
      {
        key: 'parentCarrierName',
        content: 'Parent carrier name',
        shouldTruncate: true,
        isSortable: true,
      },
      {
        key: 'action',
        content: '',
        shouldTruncate: true,
        // width: 120
      },
    ],
  };
};

export const head = createHead(true);

export const createRow = (
  users: any,
  language: string,
  t: TFunction<'translation'>,
  onOpenModal?: (jobId: string) => any,
  onDelete?: (userId: string) => any,
) => {

  const deleteUser = (id: string) => {
    console.log('id :>> ', id);
  }

  return users.map((user: any, index: number) => {
    return {
      key: `row-${index}-${user.id}`,
      cells: [
        {
          key: `${index}-${user.fullName}-fullName`,
          content: user.fullName || '-',
        },
        {
          key: `${index}-${user.phoneNumber}-phoneNumber`,
          content: formatPhoneNumber(user.phoneNumber) || '-',
        },
        {
          key: `${index}-${user.parentCarrierName}-carrierName`,
          content: user.parentCarrierName || '-',
          // content: (
          //   <div>
          //     {/* <SpanHover onClick={() => navigate(`/jobs/${user.jobId}`)}> */}
          //     <SpanHover onClick={() => onOpenModal ? onOpenModal(user.jobId) : null}>
          //       {user.parentCarrierName || '-'}
          //       {` `}
          //       <ShortcutIcon label="shortcut" size="small" />
          //     </SpanHover>
          //   </div>
          // )
        },
        {
          key: `${index}-${user.status}-status`,
          content: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconWrapper
                data-testid={"edit-user-button"}
                onClick={() =>
                  navigate(`/drivers/${user.id}`, {
                    state: {
                      id: user.id,
                    },
                  })
                }>
                <EditIcon label="edit" size="medium" />
              </IconWrapper>
              <IconWrapper
                data-testid={"delete-button"}
                onClick={() => onDelete && onDelete(user.id)}>
                <TrashIcon label="delete" size="medium" />
              </IconWrapper>
            </div>
          )
        },
      ],
    };
  });
};

const SpanHover = styled.span`
  cursor: pointer;
`

const Channel = styled.span`
  display: flex;
  align-items: center;
`
