// leave off @2x/@3x
export const images = {
  truck: require("../images/Truck1.png"),
  truck2: require("../images/Truck2.png"),
  truck3: require("../images/Truck3.png"),
  truck4: require("../images/Truck4.png"),
  truck5: require("../images/Truck5.png"),
  truck6: require("../images/Truck6.png"),
  truck7: require("../images/Truck7.png"),
  truck8: require("../images/Truck8.png"),
  truck9: require("../images/Truck9.png"),
  truck10: require("../images/Truck10.png"),
  truck11: require("../images/Truck11.png"),
  truck12: require("../images/Truck12.png"),
  truck13: require("../images/Truck13.png"),
  truck14: require("../images/Truck14.png"),
  truck15: require("../images/Truck15.png"),
  truck16: require("../images/Truck16.png"),
  truck17: require("../images/Truck17.png"),
  truck18: require("../images/Truck18.png"),
  truck19: require("../images/Truck19.png"),
  truck20: require("../images/Truck20.png"),
  truck21: require("../images/Truck21.png"),
  truck22: require("../images/Truck22.png"),
  truck23: require("../images/Truck23.png"),
  truck24: require("../images/Truck24.png"),
  truck25: require("../images/Truck25.png"),
  truck26: require("../images/Truck26.png"),
  truck27: require("../images/Truck27.png"),
  truck28: require("../images/Truck28.png"),
  truck29: require("../images/Truck29.png"),
  truck30: require("../images/Truck30.png"),
  truck31: require("../images/Truck31.png"),
  truck32: require("../images/Truck32.png"),
  truck33: require("../images/Truck33.png"),
  truck34: require("../images/Truck34.png"),
  truck35: require("../images/Truck35.png"),
  truck36: require("../images/Truck36.png"),
  greyMock: require("../images/truck-back.png"),
}

// export default images
