import styled from 'styled-components';

export const SectionHeader = styled.span`
  color: #333;
  margin: 10px 0;
  font-size: 18px;

  :first-letter {
    text-transform: capitalize;
  }
`;
