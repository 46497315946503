import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { TFunction } from 'react-i18next';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { HeadType } from '@atlaskit/dynamic-table/dist/types/types';
import { BookingDetail } from '..';

export const sortabled: any = {
  phoneNumber: true, //! Note that: DESC = true, ASC = fasle
  fullName: true,
  registerDate: true,
  jobCount: true,
  truckCount: true,
};

export const createHead = (withWidth: boolean): HeadType => {
  return {
    cells: [
      {
        key: 'requesterName',
        content: 'Requester name',
        isSortable: true,
        // width: withWidth ? '120px' : undefined,
      },
      {
        key: 'detail',
        content: 'Detail',
        shouldTruncate: true,
        isSortable: true,
        width: 20
      },
      {
        key: 'productName',
        content: 'Product name',
        shouldTruncate: true,
        isSortable: true,
      },
      {
        key: 'action',
        content: 'Action',
        shouldTruncate: true,
        width: 12
      },
    ],
  };
};

export const head = createHead(true);

export const createRow = (
  data: any,
  language: string,
  t: TFunction<"translation">,
  onOpenModal: (data: BookingDetail) => void
) => {
  console.log('keys', data);

  return data.map((booking: any, index: number) => {
    return {
      key: `row-${index}-${booking.id}`,
      cells: [
        {
          key: `${index}-${booking?.requesterProfile?.fullName}-requesterName`,
          content: booking?.requesterProfile?.fullName ?? '-',
        },
        {
          key: `${index}-${booking?.id}-detail`,
          content: (
            <Box onClick={() => onOpenModal(booking)}>
              <span className="see-list-span">{t('detail')}</span>
              {` `}
              <ShortcutIcon label="shortcut" size="small" />
            </Box>
          )
        },
        {
          key: `${index}-${booking.productName}-productName`,
          content: booking?.productName ?? '-'
        },
        {
          key: `${index}-${booking.status}-status`,
          content: (
            <Channel>
              <Link to={`/trips/add?job_id=${booking.jobId}&carrier_name=${booking?.requesterProfile?.fullName ?? ''}`}>
                <div className="see-list-trip">
                  <span className="see-list-span">{t('createTransport')}</span>
                </div>
              </Link>
            </Channel>
          )
        },
      ],
    };
  });
};

const Box = styled.span`
  cursor: pointer;
  color: #ffc107;
  padding: 3px 5px;

  &:hover {
    border-radius: 5px;
    background-color: #ffc107;
    color: #fff;
  }
`

const Channel = styled.span`
  display: flex;
  align-items: center;
`
