import React, { useEffect, useState, CSSProperties, useCallback, SyntheticEvent, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';
import { Icon } from 'react-icons-kit';
import { ic_person } from 'react-icons-kit/md/ic_person';
import { camera } from 'react-icons-kit/fa/camera';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { fontSize, gridSize } from '@atlaskit/theme/constants';
import InlineEdit from '@atlaskit/inline-edit';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { EditUserPayload } from '../../services/user-api';
import { useMst } from '../../stores/root-store';
import { IUserDetail, UserNonPersistStore } from '../../stores/user-non-persist-store';
import { DateFormat } from '../../components/simple-data';
import { UploadFileResponse } from '../../services/upload-api';
import { Col, Row } from '../../Layouts/Controller/controller';
import { TruckNonPersistStore } from '../../stores/truck-non-persist-store';
import Swal, { SweetAlertResult } from 'sweetalert2';
import SearchForm from '../../components/search-form';
import images from '../../components/Themes/images';
import MoreIcon from '@atlaskit/icon/glyph/more';
import LottieView from 'react-lottie';
import Button from '@atlaskit/button';
import { Radio } from '@atlaskit/radio';
import { formatPhoneNumber, parsePhoneNumberForApi } from '../../utils';
import LoadingBar from 'react-top-loading-bar';

interface Props {
  userId?: number;
}

const IMAGE_PROFILE: CSSProperties = {
  width: 120,
  height: 'auto',
};

const CAMERA: CSSProperties = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  padding: 0,
  minWidth: 0,
};

const ITEM_LABEL: CSSProperties = {
  margin: 0,
  fontSize: 14,
  flex: 1
}

const ITEM_BOX: CSSProperties = {
  margin: '10px 15px',
  backgroundColor: '#fff',
  borderRadius: 3,
  cursor: 'pointer'
}

interface OptionType {
  label: string;
  value: string;
}

const ImageFram = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ImagePreview = styled.div`
  width: 80px;
  height: 80px;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
`;

interface FullNameProps {
  isNoData?: boolean;
}
const Name = styled.p<FullNameProps>`
  color: ${({ isNoData }) => (isNoData ? '#AAA' : 'inherit')};
  margin: 0 0 10px 0;
  font-size: 1.125rem;
  font-weight: 700;

  :first-letter {
    text-transform: capitalize;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailLabel = styled.div`
  margin-top: 8px;
  color: #aaa;
`;

const EditViewContainer = styled.div`
  z-index: 300;
  position: relative;
  width: 200px;
`;

const ReadViewContainer = styled.div`
  display: flex;
  max-width: 100%;
  padding: 5px ${gridSize() - 2}px;
  word-break: break-word;
`;

const Item = ({ id, fullName, phoneNumber, email, value, t, onChange }: any) => (<Box
  flex={1}
  style={{
    ...ITEM_BOX,
    cursor: id === value ? 'not-allowed' : 'pointer'
  }}
  onClick={() => id === value ? null : onChange({ currentTarget: { value: id } })}
>
  <Row justifyContent="flex-start" alignItem={'center'}>
    <FieldWrapperBox style={ITEM_LABEL}>
      <Label>{t('companyName')} :</Label>
      <ReadViewContainer data-testid="carrierCompanyName">
        {fullName || <div style={{ color: '#aaa' }}>{'-'}</div>}
      </ReadViewContainer>
    </FieldWrapperBox>
    <Radio
      isChecked={id === value}
      style={{ cursor: id === value ? 'not-allowed' : 'pointer' }}
      // onChange={onChange}
      css={{}}
      name={`${id}-${fullName}`}
      value={id}
      aria-labelledby={`row-${id}-carrier row-${id}-updated`}
    />
  </Row>
  <Row justifyContent="flex-start">
    <FieldWrapperBox style={ITEM_LABEL}>
      <Label>{t('phoneNumber')} :</Label>
      <ReadViewContainer data-testid="carrierPhoneNumber">
        {formatPhoneNumber(phoneNumber) || <div style={{ color: '#aaa' }}>{'-'}</div>}
      </ReadViewContainer>
    </FieldWrapperBox>
    <FieldWrapperBox style={ITEM_LABEL}>
      <Label>{t('email')} :</Label>
      <ReadViewContainer data-testid="carrierEmail">
        {email || <div style={{ color: '#aaa' }}>{'-'}</div>}
      </ReadViewContainer>
    </FieldWrapperBox>
  </Row>
</Box>)

const Dots = () => (
  <LottieView
    options={{
      autoplay: true,
      loop: true,
      animationData: require('../../images/animations/dots-loading.json'),
    }}
    width={60}
    height={40}
  />
);

const DriverDetail: React.FC<Props> = observer((props: any) => {
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [userData, setUserData] = useState<IUserDetail | null>(null);
  const { loginStore, uploadFileStore } = useMst();
  const [files, setFiles] = useState<UploadFileResponse[]>([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState({});
  const [value, setValue] = useState<string>('1');
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const ref = useRef<any>(null);

  const userId = props.userId;
  type Fields = 'fullName' | 'email' | 'legalType' | 'phoneNumber' | 'attachCode' | 'userType';

  const breadcrumbs = (
    <Breadcrumbs onExpand={() => { }}>
      <BreadcrumbsItem onClick={() => navigate('/drivers')} text={t('driverManagement')} key="driver-management" />
      <BreadcrumbsItem text={t('driverInfo')} key="driver-info" />
    </Breadcrumbs>
  );

  useEffect(() => {
    ref?.current?.continuousStart();
    uploadFileStore.clear();
    UserNonPersistStore.getUserDetailV2(userId, { isRequiredCarrier: true });

    return () => {
      setFiles([]);
      uploadFileStore.clear();
      UserNonPersistStore.clear();
      UserNonPersistStore.setPagination({
        size: 10,
        totalElements: 0,
        totalPages: 0,
        currentPage: 1,
      });
    };
  }, []);

  useEffect(() => {
    console.log('TRIGGER Patch Profile => ', JSON.parse(JSON.stringify(UserNonPersistStore.data_patch_user)));
  }, [JSON.stringify(UserNonPersistStore.data_patch_user)]);
  useEffect(() => {
    console.log('TRIGGER TruckNonPersistStore => userTrucks_loading  : ', TruckNonPersistStore.userTrucks_loading);
  }, [TruckNonPersistStore.userTrucks_loading]);

  useEffect(() => {
    const tmpProfile = JSON.parse(JSON.stringify(UserNonPersistStore.userDetail));
    console.log('tmpProfile :>> ', tmpProfile);
    if (tmpProfile) {
      if (tmpProfile?.parentCarrier?.phoneNumber) {
        tmpProfile.parentCarrier.phoneNumber = formatPhoneNumber(tmpProfile.parentCarrier.phoneNumber);
      }
      const user: IUserDetail = {
        ...tmpProfile,
        phoneNumber: formatPhoneNumber(tmpProfile.phoneNumber),
      };
      if (tmpProfile?.avatar) setPreviewImage(tmpProfile.avatar);
      setUserData(user);
      setValue(tmpProfile?.parentCarrierId ?? '');
      if (firstLoading) {
        ref?.current?.complete();
        setFirstLoading(false);
      }
    }
  }, [JSON.stringify(UserNonPersistStore.userDetail)]);

  const handleChangeImage = (event: any) => {
    event.preventDefault();
    if (event.target?.files?.length) {
      const previewImg = URL.createObjectURL(event.target.files[0]);
      console.log(`🚀  ->  previewImg`, previewImg);
      console.log('Image Pciker :: ', event.target.files[0]);
      UserNonPersistStore.uploadAvatarProfile('USER_AVATAR/INPROGRESS/', event.target.files[0], userId);
      setPreviewImage(previewImg);
    }
  };

  useEffect(() => {
    let tmpErrorPatchUser = JSON.parse(JSON.stringify(UserNonPersistStore.error_patch_user));
    if (tmpErrorPatchUser) {
      Swal.fire({
        icon: 'error',
        text: tmpErrorPatchUser.includes('Phone number')
          ? `${t('errorProfile.wrongFormatPhoneNumber')}!`
          : `${t('errorProfile.someThingWrong')}`,
      }).then(() => UserNonPersistStore.clearErrorPatchUser());
    }
  }, [JSON.stringify(UserNonPersistStore.error_patch_user)]);

  const handleSave = (field: Fields, value: any) => {
    const payload: Partial<EditUserPayload> = { [field]: value };
    UserNonPersistStore.updateUserDetail(userId, payload);
  };

  const setInitialSortingAndPage = () => {
    const options = { page: 1, descending: true, userType: 'CARRIER' };
    setSearchValue(options);
    UserNonPersistStore.getUsersV2(options, true);
  };

  const onSearch = (value: string) => {
    console.log('value :>> ', value);
    if (value) {
      let search = {
        page: 1,
        textSearch: value,
        descending: true,
        userType: 'CARRIER'
      };
      setPage(1);
      setSearchValue(search);
      UserNonPersistStore.getUsersV2(search, true);
    } else {
      setPage(1);
      setInitialSortingAndPage();
    }
  }

  const onLoadMore = (page: number) => {
    let search = {
      ...searchValue,
      page
    }
    setPage(page);
    setSearchValue(search);
    UserNonPersistStore.getUsersV2(search, true);
  }

  const onSelectChoice = useCallback(
    ({ currentTarget: { value } }: SyntheticEvent<any>) => {
      const newCarrier = JSON.parse(JSON.stringify(UserNonPersistStore.userData.content))?.find((data: any) => data.id === value);
      if (Object.keys(newCarrier).length) {
        UserNonPersistStore.updateUserDetail(userId, {
          parentCarrierId: value
        });
        UserNonPersistStore.setUserDetail({
          parentCarrierId: newCarrier.id,
          parentCarrierName: newCarrier.fullName,
          parentCarrier: {
            id: newCarrier.id,
            fullName: newCarrier.fullName,
            phoneNumber: newCarrier.phoneNumber,
            email: newCarrier.email,
            userType: newCarrier.userType,
            userId: newCarrier.id,
            createdAt: newCarrier.createdAt,
            legalType: newCarrier.legalType
          }
        });
        setValue(value);
      }
    },
    [setValue],
  );

  const preSelectChoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    Swal.fire({
      icon: 'info',
      text: `ยืนยันการเปลี่ยนเจ้าของรถ!`,
      showCancelButton: true,
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        Swal.fire('Saved!', '', 'success');
        onSelectChoice(event);
      }
    });
  }

  if (!userData) return <LoadingBar color="#ffc107" ref={ref} />;

  return (
    <>
      <PageHeader breadcrumbs={breadcrumbs}>{t('driverInfo')}</PageHeader>

      <Page>
        <Row>
          <Box flex={1} style={{ borderRight: '1px solid #ddd' }}>

            <HeaderBox>
              <img src={images['truck']} style={{ width: 30, height: 30 }} />
              <Topic>{t('drivers')}</Topic>
            </HeaderBox>

            <Row style={{ flexDirection: 'row' }}>
              <Col
                flex={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ImageFram>
                  <ImagePreview>
                    {previewImage ? (
                      <img src={previewImage} alt="" style={IMAGE_PROFILE} />
                    ) : (
                      <Icon icon={ic_person} size={50} />
                    )}
                  </ImagePreview>
                  <Field label="" name="imageProfile" defaultValue="">
                    {({ fieldProps, error, meta: { valid } }: any) => (
                      <MaterialButton
                        variant="contained"
                        component="label"
                        style={CAMERA}
                        onChange={(event: any) => handleChangeImage(event)}
                      >
                        <input type={'file'} accept={'image/*'} />
                        <Icon icon={camera} size={25} />
                      </MaterialButton>
                    )}
                  </Field>
                </ImageFram>
              </Col>

              <Col flex={3}>
                <FieldWrapper>
                  <InlineEdit
                    defaultValue={userData.fullName}
                    editView={({ errorMessage, ...fieldProps }) => (
                      <Textfield {...fieldProps} css={{ width: '100%' }} autoFocus />
                    )}
                    readView={() => (
                      <ReadViewContainer data-testid="userFullname" style={{ paddingLeft: 0 }}>
                        <Name isNoData={!userData?.fullName}>{userData?.fullName || t('fullNamePlaceholder')}</Name>
                      </ReadViewContainer>
                    )}
                    onConfirm={(value) => {
                      if (!value) return;
                      return handleSave('fullName', value);
                    }}
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <DetailLabel>{t('phoneNumber')} :</DetailLabel>
                  <InlineEdit
                    defaultValue={userData.phoneNumber}
                    editView={({ errorMessage, ...fieldProps }) => (
                      <Textfield {...fieldProps} css={{ width: '100%' }} autoFocus />
                    )}
                    readView={() => (
                      <ReadViewContainer data-testid="phoneNumberField">
                        {userData.phoneNumber || <div style={{ color: '#aaa' }}>{t('clickToEnterValue')}</div>}
                      </ReadViewContainer>
                    )}
                    onConfirm={(value) => {
                      console.log(`🚀  ->  value`, value);
                      if (!value) return;
                      if (value.startsWith('0')) value = `+66${value.substr(1)}`;
                      else {
                        value = parsePhoneNumberForApi(value)
                      }
                      return handleSave('phoneNumber', value);
                    }}
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <DetailLabel>{t('email')} :</DetailLabel>
                  <InlineEdit
                    defaultValue={userData.email}
                    // label={t('email')}
                    editView={({ errorMessage, ...fieldProps }) => <Textfield {...fieldProps} autoFocus />}
                    readView={() => (
                      <ReadViewContainer data-testid="emailField">
                        {userData.email || <div style={{ color: '#aaa' }}>{t('clickToEnterValue')}</div>}
                      </ReadViewContainer>
                    )}
                    onConfirm={(value) => handleSave('email', value)}
                  />
                </FieldWrapper>

                <FieldWrapper>
                  <DetailLabel>{t('memberSince')} :</DetailLabel>
                  <div style={{ marginTop: 8, marginLeft: 5 }}>
                    {DateFormat(userData.createdAt as string, loginStore.language)}
                  </div>
                </FieldWrapper>

                <FieldWrapperBox>
                  <Label>{t('userType')} :</Label>
                  <ReadViewContainer data-testid="userTypeField">
                    {/* {t('drivers')} */}
                    DRIVER
                  </ReadViewContainer>
                </FieldWrapperBox>
              </Col>
            </Row>

            <div style={{ borderTop: '1px solid #ddd', margin: '30px 0 10px 0' }} />

            <HeaderBox>
              <img src={images['userIcon']} style={{ width: 25, height: 25 }} />
              <Topic>{t('carOwner')} {!userData?.parentCarrierId && <Warning>({t('notLinkedCarrier')})</Warning>}</Topic>
            </HeaderBox>

            {userData?.parentCarrierId && <Row>
              <Col
                flex={1}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ImageFram>
                  <ImagePreview>
                    {userData.parentCarrier?.avatar ? (
                      <img src={userData.parentCarrier.avatar} alt="" style={IMAGE_PROFILE} />
                    ) : (
                      <Icon icon={ic_person} size={50} />
                    )}
                  </ImagePreview>
                </ImageFram>
              </Col>

              <Col flex={3}>
                <FieldWrapperBox>
                  <ReadViewContainer data-testid="userFullname" style={{ paddingLeft: 0 }}>
                    <Name isNoData={!userData?.parentCarrier?.fullName}>{userData?.parentCarrier?.fullName || t('fullNamePlaceholder')}</Name>
                  </ReadViewContainer>
                </FieldWrapperBox>

                <FieldWrapperBox>
                  <Label>{t('memberSince')} :</Label>
                  <ReadViewContainer data-testid="memberSince">
                    {DateFormat(userData?.parentCarrier?.createdAt as string, loginStore.language)}
                  </ReadViewContainer>
                </FieldWrapperBox>

                <FieldWrapperBox>
                  <Label>{t('legalType')} :</Label>
                  <ReadViewContainer data-testid="legalTypeField">
                    {userData?.parentCarrier?.legalType === 'INDIVIDUAL' ? t('individual') : t('company')}
                  </ReadViewContainer>
                </FieldWrapperBox>

                <FieldWrapperBox>
                  <Label>{t('userType')} :</Label>
                  <ReadViewContainer data-testid="userTypeField">
                    {userData?.parentCarrier?.userType ? t(`userTypeGroup.${userData?.parentCarrier?.userType}`) : '-'}
                  </ReadViewContainer>
                </FieldWrapperBox>

                <FieldWrapperBox>
                  <Label>{t('phoneNumber')} :</Label>
                  <ReadViewContainer data-testid="phoneNumberField">
                    {userData?.parentCarrier?.phoneNumber || <div style={{ color: '#aaa' }}>{'-'}</div>}
                  </ReadViewContainer>
                </FieldWrapperBox>

                <FieldWrapperBox>
                  <Label>{t('email')} :</Label>
                  <ReadViewContainer data-testid="emailField">
                    {userData?.parentCarrier?.email || <div style={{ color: '#aaa' }}>{'-'}</div>}
                  </ReadViewContainer>
                </FieldWrapperBox>
              </Col>
            </Row>}
          </Box>

          <Box flex={1}>

            <HeaderBox style={{ paddingLeft: 0 }}>
              <img src={images['questionMark']} style={{ width: 25, height: 25 }} />
              <Topic>{t('searchCarrier')}</Topic>
            </HeaderBox>

            <SearchForm
              style={{
                width: '100%',
                paddingTop: 15
              }}
              onSearch={onSearch}
            />

            <ItemBox>
              {UserNonPersistStore?.userData?.content?.map((user: any, index: number) => (
                <Item
                  key={`carrier-${index}`}
                  id={user.id}
                  value={value}
                  fullName={user.fullName}
                  phoneNumber={user.phoneNumber}
                  email={user.email}
                  t={t}
                  onChange={preSelectChoice}
                />
              ))}

              {UserNonPersistStore.userData?.content?.length > 0 && page < (UserNonPersistStore.pagination.totalPages ?? 0) && (
                <div style={{ paddingBottom: 5, display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                  <ButtonLoadMore onClick={() => onLoadMore(page + 1)}>
                    <span style={{ width: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {!UserNonPersistStore.loading ? <MoreIcon label={'more-icon'} size={'medium'} /> : <Dots />}
                    </span>
                  </ButtonLoadMore>
                </div>
              )}
            </ItemBox>
          </Box>
        </Row>
      </Page>
    </>
  );
});

export default DriverDetail;

const Box = styled(Col)`
  padding: 15px;
`

const FieldWrapperBox = styled(FieldWrapper)`
  margin-top: 8px;
`

const Label = styled(DetailLabel)`
  margin-top: 0 !important;
`

const HeaderBox = styled.div`
  padding-left: 15px;
  margin-top: 8px;
  display: flex;
  align-items: center;
`

const Topic = styled.span`
  padding-left: 10px;
  font-size: 18px;
  font-weight: bold;
`

const ItemBox = styled.div`
  margin: 0 3px;
  border-radius: 5px;
  max-height: 650px;
  overflow: auto;
  background-color: #ededed;
`

const ButtonLoadMore = styled(Button)`
  border: 1px solid #cccccc !important;
  color: #000 !important;
  background-color: #cccccc !important;
  align-items: center;
`;

const Warning = styled.span`
  color: red;
`
