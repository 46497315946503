import React, { useState, useEffect, useCallback, Fragment, memo, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import DynamicTable from '@atlaskit/dynamic-table';
import { head, createRow, sortabled } from './dynamic-table/sample-data';
import { useMst } from '../../stores/root-store';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { defaultAlertSetting } from '../simple-data';
import Alert from '../alert';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
import SearchForm from '../search-form';
import { BookingStore } from '../../stores/booking-store'
import { RowType } from '@atlaskit/dynamic-table/dist/types/types';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { Col, Row } from '../../Layouts/Controller/controller';
import { findProvinceById, formatPhoneNumber } from '../../utils';
import { Icon } from 'react-icons-kit';
import { ic_person } from 'react-icons-kit/md/ic_person';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { GoogleMapsAPI } from '../google-map-with-search/client-config';
import { compose, withProps, withStateHandlers } from "recompose";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import Spinner from '@atlaskit/spinner';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Link } from 'gatsby';

// const google = window.google;

export interface BookingDetail {
  id: string
  jobId: string
  truckId: string
  productTypeId?: number
  productType?: string | null
  productName?: string
  truckType?: number
  accepterProfile: {
    fullName?: string
    email?: string
    mobileNo?: string
    avatar?: {
      object?: string
    }
    userId?: string
  }
  requesterProfile: {
    fullName?: string
    email?: string
    mobileNo?: string
    avatar?: {
      object?: string
    }
    userId?: string
  }
  weight?: number
  requiredTruckAmount?: number
  from?: {
    name?: string
    dateTime?: string
    contactName?: string
    contactMobileNo?: string
    lat?: string
    lng?: string
  }
  to?: {
    name?: string
    dateTime?: string
    contactName?: string
    contactMobileNo?: string
    lat?: string
    lng?: string
  }[]
  truckDetail?: {
    id?: string
    tipper?: boolean
    workZone?: string[]
    truckType?: string
    stallHeight?: string
    loadingWeight?: number
    registrationNumber?: string[]
  }
  price?: number
  priceType?: string
}

type CoordinateProps = {
  name?: string
  lat: number
  lng: number
}[]

interface GoogleProps {
  bookingId: string
  coordinates: CoordinateProps
  isOpen?: string
  onToggleOpen: ({ isOpen }: any) => void
}

interface Props { }

interface DetailBoxProps {
  header?: string
  id?: string
  fullName?: string
  mobileNo?: string
  email?: string
  image?: string
  linkTo?: string
  t: (text: string) => any
}

const IMAGE: CSSProperties = {
  width: 100,
  height: 100,
  borderRadius: '50%',
  backgroundColor: '#ebeef3',
  padding: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const Image = memo(
  ({ src, id, onError }: any) => (
    <img
      src={src}
      style={IMAGE}
      onError={onError}
    />
  ),
  (prevProps, nextProps) => {
    if (prevProps.id === nextProps.id) {
      return true;
    }
    return false;
  },
);

const Detail = ({ topic, content, style = {} }: any) => (
  <div style={style}>
    <Label>{`${topic} :`}</Label>
    {typeof content === 'string' ? <Value>{content}</Value> : content}
  </div>
);

const DetailBox = (data: DetailBoxProps) => (<ColDetail>
  <Header>
    <HeaderText>{data.header}</HeaderText>
    <Link to={data?.linkTo ?? '#'} target="_blank">
      <ShortcutIcon label="shortcut" size="small" />
    </Link>
  </Header>
  <div style={{ display: 'flex' }}>
    <Box flex={1}>
      {data.image ? <Image
        src={`${process.env.API_ENDPOINT}/api/v1/media/file-stream?attachCode=${data.image}`}
        id={data.id}
        onError={() => { }}
      /> : <div style={IMAGE}>
        <Icon icon={ic_person} size={80} />
      </div>}
    </Box>
    <Box flex={2}>
      <Detail topic={data.t('fullName')} content={data?.fullName} />
      <Detail topic={data.t('phoneNumber')} content={data?.mobileNo} />
    </Box>
  </div>
</ColDetail>)

const Maps = compose<GoogleProps, any>(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: 250 }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withStateHandlers(() => ({
    isOpen: '',
  }), {
    onToggleOpen: () => ({ isOpen }: any) => ({
      isOpen: isOpen
    })
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <Fragment>
    <GoogleMap defaultZoom={7} defaultCenter={{ lat: +props.coordinates[0].lat, lng: +props.coordinates[0].lng }}>
      {props.coordinates.map(({ lat, lng, name }, index) => (
        <Fragment key={`${props.bookingId}-${index}`}>
          {props.isOpen === `${props.bookingId}-${index}` && <InfoBox
            defaultPosition={new google.maps.LatLng(+lat, +lng)}
            options={{ closeBoxURL: ``, enableEventPropagation: true }}
          >
            <div style={{ backgroundColor: `#ffc107`, opacity: 0.9, padding: 12, maxWidth: 220 }} onClick={() => props.onToggleOpen('')} >
              <span style={{ fontSize: 14, color: `#000000` }}>{name}</span>
            </div>
          </InfoBox>}
          <Marker
            position={{ lat: +lat, lng: +lng }}
            label={{
              text: index === 0 ? 'ขึ้น' : 'ลง',
              color: '#fff'
            }}
            onClick={() => props.onToggleOpen({ isOpen: `${props.bookingId}-${index}` })}
          />
        </Fragment>
      ))}
    </GoogleMap>
  </Fragment>
));

const MAIN_COLOR: string = '#c4c4c4';
const BookingContainer: React.FC<Props> = observer(() => {
  const { t } = useTranslation();
  const { loginStore, productTypesStore, truckTypesStore } = useMst();
  const [rowData, setRowData] = useState<(RowType)[]>([]);
  const [rowLength, setRowLength] = useState(10);
  const [alertSetting, setAlertSetting] = useState(defaultAlertSetting);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState({});
  const [sortable, setSortable] = useState(sortabled);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [bookingDetail, setBookingDetail] = useState<BookingDetail | null>(null);
  const [tempBookingDetail, setTempBookingDetail] = useState<BookingDetail | null>(null);
  const [coordinates, setCoordinates] = useState<CoordinateProps>([]);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const breadcrumbs = (
    <Breadcrumbs onExpand={() => { }}>
      <BreadcrumbsItem text={t('bookingManagement')} key="booking-management" />
    </Breadcrumbs>
  );

  const setInitialSortingAndPage = () => {
    const options = { page: 1, descending: true, requesterType: 'TRUCK_OWNER' };
    setSearchValue(options);
    BookingStore.getBooking(options);
  };

  useEffect(() => {
    setInitialSortingAndPage();
    const truckTypes = JSON.parse(JSON.stringify(truckTypesStore.data));
    if (!truckTypes?.length) {
      truckTypesStore.getTruckTypes();
    }
  }, []);

  useEffect(() => {
    const bookingData = JSON.parse(JSON.stringify(BookingStore?.detail));
    /**
     * bookingData.id คือ jobId เนื่องจาก api ไม่ได้ส่ง bookingId มาให้แต่ส่ง jobId เป็น primary key(id) มาแทน
     */
    if (bookingData && tempBookingDetail && bookingData?.id === tempBookingDetail.jobId) {
      setBookingDetail(() => {
        const {
          weight,
          requiredTruckAmount,
          from,
          to,
          quotations,
          price,
          priceType
        } = bookingData;
        setCoordinates([{ lat: from?.lat ?? 13.7244416, lng: from?.lng ?? 100.3529157, name: from?.name ?? '-' }, ...to])
        const truckDetail = tempBookingDetail?.truckId && quotations?.length
          ? quotations.find(({ id }: any) => id === tempBookingDetail.id)
          : null;
        return {
          ...tempBookingDetail,
          weight,
          requiredTruckAmount,
          from,
          to,
          truckDetail: truckDetail?.truck
            ? {
              ...truckDetail.truck,
              truckType: truckDetail.truck?.truckType ? truckTypesStore.data?.find(({ id }: any) => id === truckDetail.truck.truckType)?.name : '-',
              workZone: truckDetail.truck?.workZone?.map((zone: any) => findProvinceById(zone.province)?.label)?.join(', ') ?? []
            }
            : null,
          price,
          priceType
        }
      });
    }
  }, [JSON.stringify(BookingStore.detail), tempBookingDetail]);

  useEffect(() => {
    if (BookingStore.error_response) {
      setAlertSetting({
        icon: 'error',
        show: true,
        type: 'general',
        title: BookingStore.error_response.title || '',
        content: BookingStore.error_response.content || '',
      });
    } else {
      setAlertSetting(defaultAlertSetting);
    }
  }, [BookingStore.error_response]);

  useEffect(() => {
    const booking = JSON.parse(JSON.stringify(BookingStore.bookingData));
    if (booking?.content?.length) {
      const rows = createRow(booking.content, loginStore.language, t, onOpenModal);
      setRowData(rows);
      const rowLen = BookingStore?.pagination?.size;
      rowLen && setRowLength(rowLen);
    }
  }, [JSON.stringify(BookingStore.bookingData)]);

  const onSearch = (value: string) => {
    console.log('value :>> ', value);
    if (value) {
      let search = {
        page: 1,
        searchText: value,
        requesterType: 'TRUCK_OWNER',
        descending: true,
      };
      setPage(1);
      setSearchValue(search);
      BookingStore.getBooking(search);
    } else {
      setPage(1);
      setInitialSortingAndPage();
    }
  };

  const onHandlePage = (pagination: number) => {
    setPage(pagination);
    const searchParams = { ...searchValue, page: pagination };
    setSearchValue(searchParams);
    BookingStore.getBooking(searchParams);
  }

  const onOpenModal = (data: BookingDetail) => {
    setTempBookingDetail(() => {
      const productType = data?.productTypeId ? productTypesStore.productTypeNameById(data.productTypeId) : undefined
      return {
        ...data,
        productType: productType?.name
      }
    });
    BookingStore.getDetail(data.id);
    openModal();
  }

  const onCloseModal = () => {
    setTempBookingDetail(null);
    setBookingDetail(null);
    setCoordinates([]);
    closeModal();
  }

  // const Navigate = (url: string) =>

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <PageHeader breadcrumbs={breadcrumbs}>{t('bookingManagement')}</PageHeader>
      </div>
      {alertSetting.show && <Alert setting={alertSetting} />}

      <SearchForm onSearch={(value: any) => onSearch(value)} />

      <span>{`${t('resultsFound')}: ${BookingStore.pagination?.totalElements || 0}`}</span>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={onCloseModal} width={'x-large'}>
            <ModalHeader>
              <ModalTitle>{'รายละเอียดการจอง'}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {bookingDetail ? <Fragment>
                <Row>
                  <ColDetail style={{ margin: '0 0 15px 0' }}>
                    <Header>
                      <HeaderText>ข้อมูลงาน</HeaderText>
                      <Link to={`/jobs/${bookingDetail.jobId}`} target="_blank">
                        <ShortcutIcon label="shortcut" size="small" />
                      </Link>
                    </Header>
                    <Row>
                      <Col flex={1}>
                        <Detail topic={t('productName')} content={bookingDetail?.productName} />
                        <Detail topic={t('productType')} content={bookingDetail?.productType} />
                        <Detail topic={t('weight')} content={`${bookingDetail?.weight ?? '-'} ${t('ton')}`} />
                      </Col>
                      <Col flex={2}>
                        {coordinates?.length && <Maps
                          bookingId={bookingDetail.id}
                          coordinates={coordinates}
                        />}
                      </Col>
                    </Row>
                  </ColDetail>
                </Row>

                <Row>
                  <ColDetail style={{ margin: '0 0 15px 0' }}>
                    <Header>
                      <HeaderText>ข้อมูลรถ</HeaderText>
                      {bookingDetail?.truckDetail && <Link to={`/vehicles/${bookingDetail.truckId}`} target="_blank">
                        <ShortcutIcon label="shortcut" size="small" />
                      </Link>}
                    </Header>
                    {bookingDetail?.truckDetail ? <Row>
                      <Col>
                        <Detail topic={t('registrationNumber')} content={bookingDetail?.truckDetail?.registrationNumber?.join(', ')} />
                        <Detail topic={t('truckType')} content={bookingDetail?.truckDetail?.truckType ?? '-'} />
                        <Detail topic={t('stallHeight')} content={bookingDetail?.truckDetail?.stallHeight ?? '-'} />
                      </Col>
                      <Col>
                        <Detail topic={t('weight')} content={`${bookingDetail?.truckDetail?.loadingWeight ?? '-'} ${t('ton')}`} />
                        <Detail topic={t('zoneWork')} content={bookingDetail?.truckDetail?.workZone?.toString() ?? '-'} />
                      </Col>
                    </Row> : <Label>(ไม่มีข้อมูลรถ)</Label>}
                  </ColDetail>
                </Row>

                <Row>
                  <DetailBox
                    header={'เจ้าของ'}
                    id={bookingDetail.accepterProfile.userId}
                    fullName={bookingDetail.accepterProfile?.fullName}
                    mobileNo={bookingDetail.accepterProfile?.mobileNo ? formatPhoneNumber(bookingDetail.accepterProfile.mobileNo) : '-'}
                    image={bookingDetail.accepterProfile?.avatar?.object}
                    linkTo={`/users/${bookingDetail.accepterProfile?.userId}`}
                    t={t}
                  />

                  <DetailBox
                    header={'ผู้ขอจอง'}
                    id={bookingDetail.requesterProfile.userId}
                    fullName={bookingDetail.requesterProfile?.fullName}
                    mobileNo={bookingDetail.requesterProfile?.mobileNo ? formatPhoneNumber(bookingDetail.requesterProfile.mobileNo) : '-'}
                    image={bookingDetail.requesterProfile?.avatar?.object}
                    linkTo={`/users/${bookingDetail.requesterProfile?.userId}`}
                    t={t}
                  />
                </Row>
              </Fragment> : <Spinner size={'large'} />}
            </ModalBody>
            <ModalFooter>
              {``}
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>

      <div className="history-call-dynamic-tbl">
        <DynamicTable
          head={head}
          rows={rowData}
          page={page}
          emptyView={<p>{t('noData')}</p>}
          testId="job-history-call"
          // sortKey={sortable.sortKey}
          // sortOrder={sortable.sortOrder === 'DESC' ? 'DESC' : 'ASC'}
          rowsPerPage={rowLength}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={BookingStore.loading}
          defaultSortOrder="DESC"
          onSort={(sort) => {
            const descending = !sortable[sort.key];
            const search = { ...searchValue, descending, sortBy: sort.key };
            setSortable({ ...sortable, [sort.key]: descending });
            setSearchValue(search);
            // userStore.getUsers(search);
          }}
          onSetPage={onHandlePage}
        />
      </div>
    </div>
  );
});
export default BookingContainer;

const Box = styled.div<{ flex?: number }>`
  flex: ${(props) => props?.flex ?? 1};
`

const Header = styled(Row)`
  margin: -15px -15px 15px -15px;
  padding: 15px;
  background-color: #eeeeee7e;
  justify-content: space-between;
  width: 100%;
`

const HeaderText = styled.h3`
  margin: 0;
  padding: 0;
`

const Label = styled.p`
  color: #999;
  font-size: 13px;
  margin: 10px 0 10px 0;
`;

const Value = styled.p`
  font-size: 16px;
  margin: 10px 0 10px 0;
`;

const ColDetail = styled(Col)`
  border: 1px solid #eeeeee7e;
  border-radius: 5px;
  margin: 0 15px 0 0;
  padding: 15px;
  box-shadow: 5px 5px 10px #eeeeee;
`
