export const provinceListTh = [
  { label: 'กรุงเทพมหานคร', value: 1, region: 1 },
  { label: 'สมุทรปราการ', value: 2, region: 1 },
  { label: 'นนทบุรี', value: 3, region: 1 },
  { label: 'ปทุมธานี', value: 4, region: 1 },
  { label: 'พระนครศรีอยุธยา', value: 5, region: 1 },
  { label: 'อ่างทอง', value: 6, region: 1 },
  { label: 'ลพบุรี', value: 7, region: 1 },
  { label: 'สิงห์บุรี', value: 8, region: 1 },
  { label: 'ชัยนาท', value: 9, region: 1 },
  { label: 'สระบุรี', value: 10, region: 1 },
  { label: 'นครนายก', value: 17, region: 1 },
  { label: 'ชลบุรี', value: 11, region: 4 },
  { label: 'ระยอง', value: 12, region: 4 },
  { label: 'จันทบุรี', value: 13, region: 4 },
  { label: 'ตราด', value: 14, region: 4 },
  { label: 'ฉะเชิงเทรา', value: 15, region: 4 },
  { label: 'ปราจีนบุรี', value: 16, region: 4 },
  { label: 'สระแก้ว', value: 18, region: 4 },
  { label: 'นครราชสีมา', value: 19, region: 3 },
  { label: 'บุรีรัมย์', value: 20, region: 6 },
  { label: 'สุรินทร์', value: 21, region: 6 },
  { label: 'ศรีสะเกษ', value: 22, region: 6 },
  { label: 'อุบลราชธานี', value: 23, region: 1 },
  { label: 'ยโสธร', value: 24, region: 6 },
  { label: 'ชัยภูมิ', value: 25, region: 6 },
  { label: 'อำนาจเจริญ', value: 26, region: 6 },
  { label: 'บึงกาฬ', value: 27, region: 6 },
  { label: 'หนองบัวลำภู', value: 28, region: 6 },
  { label: 'ขอนแก่น', value: 29, region: 6 },
  { label: 'อุดรธานี', value: 30, region: 6 },
  { label: 'เลย', value: 31, region: 6 },
  { label: 'หนองคาย', value: 32, region: 6 },
  { label: 'มหาสารคาม', value: 33, region: 6 },
  { label: 'ร้อยเอ็ด', value: 34, region: 6 },
  { label: 'กาฬสินธุ์', value: 35, region: 6 },
  { label: 'สกลนคร', value: 36, region: 6 },
  { label: 'นครพนม', value: 37, region: 6 },
  { label: 'มุกดาหาร', value: 38, region: 6 },
  { label: 'เชียงใหม่', value: 39, region: 2 },
  { label: 'ลำพูน', value: 40, region: 2 },
  { label: 'ลำปาง', value: 41, region: 2 },
  { label: 'อุตรดิตถ์', value: 42, region: 2 },
  { label: 'แพร่', value: 43, region: 2 },
  { label: 'น่าน', value: 44, region: 2 },
  { label: 'พะเยา', value: 45, region: 2 },
  { label: 'เชียงราย', value: 46, region: 2 },
  { label: 'แม่ฮ่องสอน', value: 47, region: 2 },
  { label: 'นครสวรรค์', value: 48, region: 2 },
  { label: 'อุทัยธานี', value: 49, region: 2 },
  { label: 'กำแพงเพชร', value: 50, region: 2 },
  { label: 'ตาก', value: 51, region: 2 },
  { label: 'สุโขทัย', value: 52, region: 2 },
  { label: 'พิษณุโลก', value: 53, region: 2 },
  { label: 'พิจิตร', value: 54, region: 2 },
  { label: 'เพชรบูรณ์', value: 55, region: 2 },
  { label: 'ราชบุรี', value: 56, region: 1 },
  { label: 'กาญจนบุรี', value: 57, region: 1 },
  { label: 'สุพรรณบุรี', value: 58, region: 1 },
  { label: 'นครปฐม', value: 59, region: 1 },
  { label: 'สมุทรสาคร', value: 60, region: 1 },
  { label: 'สมุทรสงคราม', value: 61, region: 1 },
  { label: 'เพชรบุรี', value: 62, region: 1 },
  { label: 'ประจวบคีรีขันธ์', value: 63, region: 1 },
  { label: 'นครศรีธรรมราช', value: 64, region: 6 },
  { label: 'กระบี่', value: 65, region: 3 },
  { label: 'พังงา', value: 66, region: 3 },
  { label: 'ภูเก็ต', value: 67, region: 3 },
  { label: 'สุราษฎร์ธานี', value: 68, region: 3 },
  { label: 'ระนอง', value: 69, region: 3 },
  { label: 'ชุมพร', value: 70, region: 3 },
  { label: 'สงขลา', value: 71, region: 3 },
  { label: 'สตูล', value: 72, region: 3 },
  { label: 'ตรัง', value: 73, region: 3 },
  { label: 'พัทลุง', value: 74, region: 3 },
  { label: 'ปัตตานี', value: 75, region: 3 },
  { label: 'ยะลา', value: 76, region: 3 },
  { label: 'นราธิวาส', value: 77, region: 3 },
];

export const provinceListEn = [
  { label: 'Bangkok', value: 1, region: 1 },
  { label: 'Samut Prakarn', value: 2, region: 1 },
  { label: 'Nonthaburi', value: 3, region: 1 },
  { label: 'Pathum Thani', value: 4, region: 1 },
  { label: 'Phra Nakhon Si Ayutthaya', value: 5, region: 1 },
  { label: 'Ang Thong', value: 6, region: 1 },
  { label: 'Lop Buri', value: 7, region: 1 },
  { label: 'Sing Buri', value: 8, region: 1 },
  { label: 'Chai Nat', value: 9, region: 1 },
  { label: 'Saraburi', value: 10, region: 1 },
  { label: 'Nakhon Nayok', value: 17, region: 1 },
  { label: 'Chon Buri', value: 11, region: 4 },
  { label: 'Rayong', value: 12, region: 4 },
  { label: 'Chanthaburi', value: 13, region: 4 },
  { label: 'Trat', value: 14, region: 4 },
  { label: 'Chachoengsao', value: 15, region: 4 },
  { label: 'Prachin Buri', value: 16, region: 4 },
  { label: 'Sa kaeo', value: 18, region: 4 },
  { label: 'Nakhon Ratchasima', value: 19, region: 3 },
  { label: 'Buri Ram', value: 20, region: 6 },
  { label: 'Surin', value: 21, region: 6 },
  { label: 'Si Sa Ket', value: 22, region: 6 },
  { label: 'Ubon Ratchathani', value: 23, region: 1 },
  { label: 'Yasothon', value: 24, region: 6 },
  { label: 'Chaiyaphum', value: 25, region: 6 },
  { label: 'Amnat Charoen', value: 26, region: 6 },
  { label: 'Bueng Kan', value: 27, region: 6 },
  { label: 'Nong Bua Lam Phu', value: 28, region: 6 },
  { label: 'Khon Kaen', value: 29, region: 6 },
  { label: 'Udon Thani', value: 30, region: 6 },
  { label: 'Loei', value: 31, region: 6 },
  { label: 'Nong Khai', value: 32, region: 6 },
  { label: 'Maha Sarakham', value: 33, region: 6 },
  { label: 'Roi Et', value: 34, region: 6 },
  { label: 'Kalasin', value: 35, region: 6 },
  { label: 'Sakon Nakhon', value: 36, region: 6 },
  { label: 'Nakhon Phanom', value: 37, region: 6 },
  { label: 'Mukdahan', value: 38, region: 6 },
  { label: 'Chiang Mai', value: 39, region: 2 },
  { label: 'Lamphun', value: 40, region: 2 },
  { label: 'Lampang', value: 41, region: 2 },
  { label: 'Uttaradit', value: 42, region: 2 },
  { label: 'Phrae', value: 43, region: 2 },
  { label: 'Nan', value: 44, region: 2 },
  { label: 'Phayao', value: 45, region: 2 },
  { label: 'Chiang Rai', value: 46, region: 2 },
  { label: 'Mae Hong Son', value: 47, region: 2 },
  { label: 'Nakhon Sawan', value: 48, region: 2 },
  { label: 'Uthai Thani', value: 49, region: 2 },
  { label: 'Kamphaeng Phet', value: 50, region: 2 },
  { label: 'Tak', value: 51, region: 2 },
  { label: 'Sukhothai', value: 52, region: 2 },
  { label: 'Phitsanulok', value: 53, region: 2 },
  { label: 'Phichit', value: 54, region: 2 },
  { label: 'Phetchabun', value: 55, region: 2 },
  { label: 'Ratchaburi', value: 56, region: 1 },
  { label: 'Kanchanaburi', value: 57, region: 1 },
  { label: 'Suphan Buri', value: 58, region: 1 },
  { label: 'Nakhon Pathom', value: 59, region: 1 },
  { label: 'Samut Sakhon', value: 60, region: 1 },
  { label: 'Samut Songkhram', value: 61, region: 1 },
  { label: 'Phetchaburi', value: 62, region: 1 },
  { label: 'Prachuap Khiri Khan', value: 63, region: 1 },
  { label: 'Nakhon Si Thammarat', value: 64, region: 6 },
  { label: 'Krabi', value: 65, region: 3 },
  { label: 'Phang-nga', value: 66, region: 3 },
  { label: 'Phuket', value: 67, region: 3 },
  { label: 'Surat Thani', value: 68, region: 3 },
  { label: 'Ranong', value: 69, region: 3 },
  { label: 'Chumphon', value: 70, region: 3 },
  { label: 'Songkhla', value: 71, region: 3 },
  { label: 'Satun', value: 72, region: 3 },
  { label: 'Trang', value: 73, region: 3 },
  { label: 'Phatthalung', value: 74, region: 3 },
  { label: 'Pattani', value: 75, region: 3 },
  { label: 'Yala', value: 76, region: 3 },
  { label: 'Narathiwat', value: 77, region: 3 },
];

export const regionListEn = [
  { label: 'north', value: 2 },
  { label: 'north-east', value: 6 },
  { label: 'south', value: 3 },
  { label: 'west', value: 5 },
  { label: 'center', value: 1 },
  { label: 'east', value: 4 },
  { label: 'all region', value: 7 },
];

export const regionListTh = [
  { label: 'ภาคเหนือ', value: 2 },
  { label: 'ภาคตะวันออกเฉียงเหนือ', value: 6 },
  { label: 'ภาคใต้', value: 3 },
  { label: 'ภาคตะวันตก', value: 5 },
  { label: 'ภาคกลาง', value: 1 },
  { label: 'ภาคตะวันออก', value: 4 },
  { label: 'ทั่วประเทศ', value: 7 },
];
